.select2-selection--single {
  background-color: #fff;
  border: $formBorder;
  border-radius: $formRadius;

  .select2-selection__rendered {
    color: $text-color;
    line-height: $FormElementHeight;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    display: none;
  }

  .select2-selection__placeholder {
    color: $grey-light;
  }

  .select2-selection__arrow {
    height: ($FormElementHeight - 2);

    position: absolute;

    top: 1px;
    right: 6px;

    width: 20px;

    b {
      border-color: $theme-color transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
	.select2-selection--single {
		border-color: $formFocusBorder;
		outline: 0;
		box-shadow: $formFocusBoxShadow;
		.select2-selection__arrow {
			b {
				border-color: transparent transparent $formFocusBorder transparent;
				border-width: 0 4px 5px 4px;
			}
		}
	}
}

.select2-container--focus:not(.select2-container--open) {
  outline: none;
  box-shadow: none;
}

