/**
 * Light
 */
.wysiwyg {
	@include font(14px, 1.5em);
	p {
		text-align: justify;
	}
	p, ul, ol {
		margin: $margin-md 0 $margin-md;
	}
	// Lists
	ul, ol {
		margin-left: $margin;
	}
	li {
		padding: 3px 0;
	}
	ol li {
		list-style-type: decimal;
		margin-left: 16px;
		display: list-item;
	}
	ul li {
		position: relative;
		padding-left: 16px;
		&:before {
			content: "";
			display: block;
			position: absolute;
			background: $theme-color;
			height: 6px;
			width: 6px;
			border-radius: 50%;
			left: 0;
			top: 10px;
		}
	}
	// Headings
	@include headings {
		color: $text-color;
		font-weight: $semibold;
		@include FontFamily("title");
		position: relative;
		padding-bottom: 8px;
		&:after {
			position: absolute;
			display: block;
			content: "";
			left: 0;
			bottom: 0;
			height: 2px;
			width: 40px;
			background: $theme-color;
		}
	}
	h2 {
		@include font(21px, 1.15em);
		margin: $margin-lg 0 $margin-md;
	}
	h3 {
		@include font(18px, 1.15em);
		margin: $margin-lg 0 $margin-md;
	}
	h4 {
		@include font(16px, 1.2em);
		margin: $margin 0 $margin-sm;
	}
	h5 {
		@include font(16px, 1.2em);
		margin: $margin-sm 0 $margin-xs;
	}
	h6 {
		@include font(14px, 1.2em);
		margin: $margin-xs 0 $margin-xxs;
	}
	// Blockquote
	blockquote {
		background: rgba($theme-color, 0.05);
		margin: 0 0 $margin;
		border-left: 4px solid $theme-color;
		padding: $margin-sm $margin $margin-sm $margin;
		color: $theme-color;
		font-weight: $bold;
		p {
			margin: $margin-xs 0;
		}
	}
	// Table
	table {
		margin: 0 0 $margin;
		width: 100%;
		background: $white;
		border-collapse: collapse;
		td, th {
			border-bottom: 1px solid $table-line;
			padding: 10px 12px;
		}
		th {
			padding-top: 8px;
			padding-bottom: 8px;
			font-weight: $semibold;
		}
		tbody {
			tr:nth-child(2n + 1) td {
				background: $table-nth-bg;
			}
		}
	}
	// Image
	img {
		display: inline-block;
		border-radius: $radius;
		@include font(0em);
		max-width: 100%;
		height: auto;
		margin-left: $margin-lg;
		margin-right: $margin-lg;
		&.img-right {
			margin-right: 0;
		}
		&.img-left {
			margin-left: 0;
		}
	}
	// Center
	&.wysiwyg-center {
		text-align: center;
		p {
			text-align: center;
		}
	}
	// Line
	hr {
		margin: $margin 0;
		background: none;
		border: none;
		@include font(0em);
		border-bottom: 1px solid rgba($white, 0.3);
		height: 1px;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0px;
			background: rgba($black, 0.1);
			display: block;
			height: 1px;
		}
	}
}


/**
 * Responsive table
 */
.table-responsive {
	img {
		display: inline-block;
		max-width: 100%;
		height: auto !important;
	}
}
.table-responsive-wrap {
	min-height: .01%;
	overflow-x: auto;
}
@media (max-width: $screen-sm-max) {
	.table-responsive-wrap {
		margin: $margin 0;
		width: 100%;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #ddd;
		.table-responsive {
			margin: 0;
			width: $screen-sm-max !important;
		}
	}
}


.wysiwyg-prologue {
	@include font(16px, 1.8em);
	img {
		border-radius: $radius;
	}
	p {
		margin: 0 0 $margin;
	}
	@media (max-width: $screen-sm-max) {
		@include font(15px, 1.6em);
	}
}