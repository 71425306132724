@mixin CoreButton($name, $bg, $color, $border: null)
{
	.btn-#{$name} 
	{
		background: $bg;
		border-color: $bg;
		color: $color;
		&:hover, &:focus 
		{
			background: lighten($bg, 8%);
			border-color: lighten($bg, 8%);
			color: $color;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) inset;
		}
	}
} 
@mixin CoreButtonInverse($name, $border, $color, $bg: transparent)
{
	.btn-inverse-#{$name}
	{
		border-color: $border;
		color: $border;
		background: $bg;
		&:hover, &:focus 
		{
			background: $border;
			color: $color;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) inset;
		}
	}
} 

@mixin CoreButtonSize($size, $height, $padding, $font)
{
	&.btn-#{$size} {
		height: $height;
		padding-left: $padding;
		padding-right: $padding;
		@include font($font, null);
	}
}

@mixin CoreButtonHelper($name, $normal, $inverse)
{
	@include CoreButton($name, nth($normal, 1), nth($normal, 2), nth($normal, 3));
	@include CoreButtonInverse($name, nth($inverse, 1), nth($inverse, 2), nth($inverse, 3));
}

// Use
@if $module-button {
	// Default style
	.btn {
		position: relative;
		padding: 0 $buttonPadding;
		@include font($buttonFont, null);
		@include FontFamily();
		transition: all $fast ease;
		height: $buttonHeight;
		border-radius: $buttonRadius;
		display: inline-block;
		border: $buttonBorder;
		margin: 0;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		white-space: nowrap;
		// Touch
		touch-action: manipulation;
		// User select
		user-select: none;
		// Icon
		i {
			display: inline-block;
			position: relative;
			margin-right: 2px;
		}
		span {
			margin-right: 4px;
		}
		i + span {
			margin-left: 4px;
			margin-right: 0;
		}
		span + i {
			margin-right: 0;
			margin-left: 2px;
		}
		// Block
		&.btn-block {
			display: block;
			width: 100%;
		}
		&.btn-block + .btn-block {
			margin-top: 5px;
		}
		// Other sizes
		@include CoreButtonSize(lg, $buttonHeight-lg, $buttonPadding-lg, $buttonFont-lg);
		@include CoreButtonSize(md, $buttonHeight-md, $buttonPadding-md, $buttonFont-md);
		@include CoreButtonSize(sm, $buttonHeight-sm, $buttonPadding-sm, $buttonFont-sm);
		@include CoreButtonSize(xs, $buttonHeight-xs, $buttonPadding-xs, $buttonFont-xs);
		@include CoreButtonSize(xxs, $buttonHeight-xxs, $buttonPadding-xxs, $buttonFont-xxs);
	}
	// Link
	a.btn, span.btn {
		line-height: ($buttonHeight);
		@include underline(0,0);
		// Other sizes
		&.btn-lg {line-height: $buttonHeight-lg};
		&.btn-md {line-height: $buttonHeight-md};
		&.btn-sm {line-height: $buttonHeight-sm};
		&.btn-xs {line-height: $buttonHeight-xs};
		&.btn-xxs {line-height: $buttonHeight-xxs};
	}

	// Btn group
	.btn-group {
		@include font(0em);
		text-align: center;
		.btn {
			border-radius: 0;
			&:first-child {
				border-radius: $buttonRadius 0 0 $buttonRadius;
			}
			&:last-child {
				border-radius: 0 $buttonRadius $buttonRadius 0;
			}
			&:only-child {
				border-radius: $buttonRadius;
			}
		}
	}

	// Round
	.btn-round {
		border-radius: $buttonRadiusLarge;
	}
}


@if $module-buttonStyles {
	// Default
	@include CoreButtonHelper(default, $buttonDefault, $buttonDefaultInverse);
	@include CoreButtonHelper(primary, $buttonPrimary, $buttonPrimaryInverse);
	@include CoreButtonHelper(danger, $buttonDanger, $buttonDangerInverse);
	@include CoreButtonHelper(success, $buttonSuccess, $buttonSuccessInverse);
	@include CoreButtonHelper(warning, $buttonWarning, $buttonWarningInverse);
	@include CoreButtonHelper(info, $buttonInfo, $buttonInfoInverse);
}
