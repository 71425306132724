// Use
@function CoreFontFamily($type: "base") {
	@each $name, $font in $fontList {
		@if $name == $type {
			@return $font;
		}
	}
	@return $fontDefault;
}
@mixin FontFamily($type: "base") {
	font-family: CoreFontFamily($type);
}
