
$screen-lg	: 1180px;

// Cols width
$cw_sidebarLeft-xlg: 0;


// Base colors
$text-color		: #595a69;
$body-bg		: #4b6a88;
$main-bg		: #f6fafd;
$link-color		: #bd2727;
$theme-color	: #2ec1a0;
$second-color 	: $body-bg;
$blue 			: #2d4e6f;
$red 			: #c83b5b;
$grey-light 	: #b8b8b8;
$grey-lighten 	: #edeefa;
$male 			: #6fddf5;
$female			: #e38bbb;
$table-line 	: #edeefa;
$table-nth-bg 	: #f9f9f9;
$facebook 		: #3b5998;
$vk 			: #4c75a3;

$slider-colors: $red, #e47d0e, #f1c40f, $theme-color, #afd219;

// Radius
$radius-lg	: 8px;
$radius		: 4px;
$radius-md	: 3px;
$radius-sm	: 2px;
$radius-xs	: 2px;
$radius-xxs	: 2px;

// Font weight
$xbold		: 700;
$bold		: 700;
$semibold	: 500;
$normal		: 400;
$light		: 300;
$xlight		: 300;

// Margins
$margin-xxs	: 2px;
$margin-xs	: 4px;
$margin-sm	: 8px;
$margin-md	: 16px;
$margin		: 24px;
$margin-lg 	: 36px;
$margin-xlg : 48px;

// Module: indentation
$indentationAll 	: $margin-xxs $margin-xs $margin-sm $margin-md $margin-lg $margin-xlg;
$indentationDefault : $margin;

// Font
$fontDefault	: 'Roboto';
$font-size 		: 14px;
$fontList: ('title': $fontDefault, 'base': $fontDefault, 'ico': 'FontAwesome', 'custom': 'Flaticon');

// - Form
// Height
$FormElementHeight-lg	: 56px;
$FormElementHeight		: 48px;
$FormElementHeight-md	: 40px;
$FormElementHeight-sm	: 32px;
$FormElementHeight-xs	: 26px;
$FormElementHeight-xxs	: 24px;
// - Input
$formBorderColor: #cddde9;
$formBorder: 1px solid $formBorderColor;
$formFocusBorder: darken($formBorderColor, 10%);
$formRadius: $radius-sm;

// Buttons
$buttonRadius: $radius-sm;
$buttonPadding-md: $margin-md;

// Alert
$alertPadding: 12px 14px 10px;
$alertMarginTop: $margin-xs;
$alertMarginBottom: $margin-md;


/**
 * Mixins
 */
@mixin placeholderStyle {
	&::-webkit-input-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
@mixin placeholderColor($color) {
	@include placeholderStyle {
		color: $color;
	}
}
