// Body
body {
	padding-top: $header-height;
	width: 100%;
	overflow-x: hidden;
	min-width: $screen-min;
}

.col-clear {
	padding-left: 0;
	padding-right: 0;
}

.alert {
	margin-bottom: $margin-md;
}

// Main
.main {
	background-color: $main-bg;
	padding: $margin-lg 0;
	&.main-responsive {
		@media (max-width: $screen-xs-max) {
			padding: 0;
		}
	}
}

// Header
.header {
	background-color: $body-bg;
	color: $white;
	height: $header-height;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	min-width: $screen-min;
	z-index: 100;
	@include font(14px, $line-height);

	// Responsive container
	.container {
		height: inherit;
	}

	// Logo
	.logo {
		float: left;
		a {
			display: block;
			padding: 16px 0 19px;
			img {
				height: 28px;
				width: auto;
			}
		}
		@media (max-width: $screen-xxs-max) {
			padding-left: $margin-md;
		}
	}

	// Right
	.right {
		float: right;
		.item {
			float: left;
			height: inherit;
			position: relative;
			+ .item:not(.item-search) {
				border-left: 1px solid rgba($white, 0.1);
			}
		}
		a.item-link {
			display: block;
			@include underline(0, 0);
			color: $white;
			line-height: $header-height;
			transition: background $fast ease;
			position: relative;
			&:hover {
				background: darken($body-bg, 6%);
			}
			&:before {
				display: none;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7px 5px 7px;
				border-color: transparent transparent $white transparent;
			}
			&.open:before {
				display: block;
			}
		}
	}

	// Profile
	.item-profile {
		a.item-link {
			padding: 0 ($margin-md + 18) 0 ($margin-md + 52);
			position: relative;
			height: $header-height;
			i {
				display: block;
				border-radius: 100%;
				position: absolute;
				width: 14px;
				text-align: center;
				right: $margin-md;
				font-size: 10px;
				color: $blue;
				top: 50%;
				transform: translateY(-50%);
				transition: color $fast ease;
			}
			img {
				display: block;
				border-radius: 100%;
				position: absolute;
				width: 40px;
				height: 40px;
				left: $margin-md;
				top: 50%;
				transform: translateY(-50%);
			}
			&:hover i {
				color: $theme-color;
			}
		}
		// Responsive
		@media (max-width: $screen-md-max) {
			a.item-link {
				padding-left: ($margin-md + 40);
				i {
					right: ($margin-sm + 2);
				}
				span {
					display: none;
				}
			}
		}
		@media (max-width: $screen-xxs-max) {
			a.item-link {
				padding-right: 18px;
				i {
					display: none;
				}
			}
		}
	}

	// Notify
	.item-notice {
		a.item-link {
			position: relative;
			padding: 0 18px;
			font-size: 22px;
			&:hover {
				span {
					border-color: darken($body-bg, 6%);
				}
			}
			span {
				background: $red;
				color: $white;
				height: 24px;
				min-width: 24px;
				border-radius: 100%;
				display: block;
				position: absolute;
				top: 7px;
				left: 50%;
				margin-left: -2px;
				border: 2px solid $body-bg;
				text-align: center;
				@include font(14px, 22px, $semibold);
			}
		}
	}

	// Default
	.item-default {
		a.item-link {
			display: flex;
			align-items: center;
			padding: 0 18px;
			min-height: $header-height;
			i {
				font-size: 22px;
				top: -1px;
			}
			span {
				display: block;
			}
			i + span, span + i {
				margin-left: 8px;
			}
		}
	}

	// Calendar
	.item-calendar {
		a.item-link {
			padding: 0 18px;
			font-size: 22px;
		}
	}

	// Menu
	.item-menu {
		$mt--size: 24px;
		$mt--weight: 3px;
		$mt--background: $white;
		display: none;
		a.item-link {
			padding: 0 18px;
			.menu-bars-container {
				height: $header-height;
				width: $mt--size;
				display: block;
				position: relative;
			}
			.menu-bars {
				display: block;
				position: absolute;
				width: $mt--size;
				height: $mt--weight;
				transition-property: transform;
				border-radius: 6px;
				background-color: $mt--background;
				top: 50%;
				transform: translateY(-2px);
				&:before, &:after {
					position: absolute;
					width: $mt--size;
					height: $mt--weight;
					border-radius: 6px;
					background-color: $mt--background;
					display: block;
					content: "";
				}
				&:before {
					top: -(($mt--weight * 2.5) + 1);
				}
				&:after {
					top: ($mt--weight * 2.5);
				}
			}
		}
	}

	// Search
	.item-search {
		position: relative;
	}

	// Search responsive
	.item-search-responsive {
		display: none;
		a.item-link {
			padding: 0 18px;
			font-size: 20px;
		}
	}

	// Search
	.header-search {
		p {
			background: $white;
			position: relative;
			padding-right: 54px;
			border-bottom: 1px solid $blue;
			height: 60px;
		}
		input {
			height: $header-height;
			width: 260px;
			border: none;
			background: none;
			color: $black;
			padding-left: 18px;
			@include placeholderColor(#63819f);
			font-size: 15px;
		}
		button {
			border: none;
			position: absolute;
			background: none;
			right: 0;
			top: 0;
			width: 54px;
			padding-right: 6px;
			text-align: center;
			height: $header-height;
			color: #63819f;
			transition: all $fast ease;
			font-size: 18px;
			&:hover {
				color: $white;
				cursor: pointer;
			}
		}
	}

	// Responsive
	@media (min-width: $screen-xlg-min) {
		.item-search {
			+ .item {
				border-left-width: 0 !important;
			}
		}
	}
	@media (max-width: $screen-lg-max) {
		.item-menu, .item-search-responsive {
			display: block;
		}
		.item-search {
			display: none;
		}
	}
	@media (max-width: $screen-sm-max) {
		.right .item {
			position: static;
		}
	}
	@media (max-width: $screen-xs-max) {
		.container {
			padding-right: 0;
		}
		.item-profile {
			a.item-link {
				padding-right: $margin-md;
				i {
					display: none;
				}
			}
		}
		.right .item {
			border-left-width: 0 !important;
			&.item-profile {
				> a {
					display: flex;
					align-items: center;
					img {
						position: relative;
						width: 40px;
						height: 40px;
						left: auto;
						top: auto;
						transform: none;
					}
				}
			}
			&:not(.item-menu) > a.item-link {
				padding: 0 10px !important;
				i {
					font-size: 20px !important;
				}
			}
		}
	}
	@media (max-width: $screen-xxs-max) {
		.container {
			padding-left: 0;
		}
		.right {
			display: flex;
			justify-content: space-between;
			float: none;
		}
	}
}

// Overlay
.overlay-bg {
	background: rgba($white, 0.64);
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
	position: fixed;
	display: none;
	.overlay-opened & {
		display: block;
	}
}

.header-search-results {
	$_mediaW: 34px;
	li {
		border-bottom: 1px solid $grey-lighten;
		&:last-child {
			border-bottom-width: 0;
		}
	}
	li.result-item a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $text-color;
		@include underline(0,0);
		transition: background-color $fast ease;
		&:hover {
			background-color: rgba($grey-lighten, 0.5);
		}
		i {
			@include font(14px, 1em);
			color: $grey-light;
			margin: $margin-xs;
			width: $_mediaW;
			text-align: center;
		}
		.content {
			display: flex;
			align-items: center;
		}
		.text {
			padding: $margin-sm 0;
			@include font(14px, 1.2em);
			strong {
				font-weight: $semibold;
			}
			small {
				display: block;
				font-size: 80%;
			}
		}
		.right {
			margin: $margin-xs $margin-xs $margin-xs $margin-sm;
			&.right--image {
				img {
					max-width: $_mediaW;
					max-height: $_mediaW;
					display: block;
					width: auto;
					height: auto;
				}
			}
		}
	}
	li.result-header {
		background-color: $grey-lighten;
		color: $text-color;
		font-weight: $bold;
		padding: 8px 8px 6px;
		text-transform: uppercase;
		font-size: 12px;
	}
	li.show-all a {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		color: $theme-color;
		font-weight: $semibold;
		@include font(13px, 1.2em);
		padding: ($margin-md - 2) $margin-sm;
		@include underline(0,0);
		&:hover {
			i {
				color: $blue;
			}
		}
		i {
			margin-left: $margin-sm;
			color: $grey-light;
		}
	}
	.alert {
		margin: $margin-xs;
	}
}

.overlay-box {
	position: absolute;
	right: 0;
	top: 100%;
	background-color: $white;
	box-shadow: 0 4px 12px rgba($black, 0.2);
	width: 420px;
	display: none;
	&.open {
		display: block;
	}

	// Notices
	&.overlay-notices {
		.notices {
			.notice-item {
				display: block;
				border-bottom: 1px solid $formBorderColor;
				padding: 12px $margin-md;
				padding-left: 54px;
				position: relative;
				color: $second-color;

				i {
					position: absolute;
					display: block;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					color: $theme-color;
					width: 54px;
					text-align: center;
					@include font(22px, 1em);
				}

				span {
					display: block;
					+ span {
						margin-top: $margin-xxs;
					}
				}
				.title {
					font-weight: $bold;
				}
			}
		}
	}

	// Calendar
	&.overlay-calendar {
		.title {
			padding: $margin-md $margin-md $margin-sm;
			@include font(16px, $line-height, $bold);
			color: $second-color;
		}
		.upcoming-events {
			color: $second-color;
			.event-item {
				padding: 12px $margin-md 12px $margin-sm;
				border-bottom: 1px solid $formBorderColor;
			}
			.date {
				float: left;
				font-weight: $bold;
				width: 100px;
				text-align: center;
				padding-right: $margin-sm;
			}
			.text {
				a {
					color: $second-color;
				}
			}
		}
	}

	// Menu
	&.overlay-menu {
		width: 300px;
		.menu {
			@include font(18px, 1.15em);
			a {
				padding: 14px 12px;
				display: block;
				color: $second-color;
				@include underline(0, 0);
				i {
					color: $theme-color;
					margin-right: 8px;
				}
				&:hover {
					color: $theme-color;
				}
			}
			li {
				border-bottom: 1px solid $grey-lighten;
				&:last-child {
					border-bottom-width: 0;
				}
			}
			.active {
				a {
					font-weight: $bold;
				}
			}
		}
	}

	// User menu
	&.overlay-user-menu {
		width: 180px;
		ul {
			a {
				padding: 14px (12px + 14px) 14px 12px;
				display: block;
				color: $body-bg;
				transition: color $fast ease;
				@include underline(0, 0);
				position: relative;
				font-weight: $semibold;
				i {
					display: block;
					position: absolute;
					right: 12px;
					top: 50%;
					transform: translateY(-50%);
				}
				&:hover {
					color: $theme-color;
				}
			}
			li {
				border-bottom: 1px solid $grey-lighten;
				&:last-child {
					border-bottom-width: 0;
				}
			}
		}
	}

	// Search
	&.overlay-search {
		//background: $blue;
		width: 300px;
		.header-search {
			p {
				background: $white;
			}
			input {
				width: 100%;
				color: $text-color;
				@include placeholderColor($grey-light);
			}
			button {
				color: $second-color;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}

	// Search result
	&.overlay-search-result {
		width: auto;
		left: 0;
	}

	// Responsive
	@media (max-width: $screen-sm-max) {
		width: 100% !important;
		&:before {
			display: none;
		}
	}
}

// Footer
.footer {
	$_color: lighten(#6a93bb, 20%);
	color: $_color;
	padding: $margin 0;
	a {
		color: $_color;
	}

	// Container
	.footer-container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	// Copyright
	.copyright {
		font-weight: $bold;
		padding-top: 12px;
	}

	// Menu
	.menu {
		padding-top: 11px;
		a {
			color: $white;
			@include underline(1, 0);
		}
		li {
			display: inline-block;
			margin: 0 $margin-sm;
		}
	}

	// Languages
	.footer-languages {
		display: flex;
		align-items: center;
		position: relative;
		margin-left: -($margin-md / 2);
		margin-right: -($margin-md / 2);
		flex-wrap: wrap;
		margin-top: $margin-sm;
		@include font(12px, 1.3em, $semibold);
		li {
			padding-left: ($margin-md / 2);
			padding-right: ($margin-md / 2);
			margin-bottom: $margin-xs;
			&.active a {
				color: $white;
				@include underline(0,0);
			}
		}
		a {
			display: flex;
			align-items: center;
			@include underline(1, 0);
			.f16 {
				margin-right: $margin-xs;
			}
		}
	}

	// Responsive
	@media (max-width: $screen-md-max) {
		// Container
		.footer-container {
			flex-wrap: wrap;
		}
		.mobile-app {
			width: 100%;
			margin-top: $margin;
			text-align: center;
		}
		// Copyright
		.footer-languages {
			justify-content: center;
		}
		.menu {
			li {
				margin: 0 $margin-sm 0 0;
			}
		}
	}
	@media (max-width: $screen-sm-max) {
		// Container
		.footer-container {
			display: block;
			text-align: center;
		}
		// Copyright
		.copyright {
		}
		.menu {
			margin-top: $margin-sm;
			li {
				margin: 0 $margin-sm 0 0;
			}
		}
	}
}

/**
 * Sidebar
 */
.sidebar {
	.menu {
		border-right: 3px solid $theme-color;
		@include font(21px, 1.15em);
		a {
			color: $second-color;
			@include underline(0, 0);
			i {
				color: $theme-color;
				margin-right: 8px;
			}
			&:hover {
				color: $theme-color;
			}
		}
		li {
			padding: $margin-sm $margin-sm;
		}
		.active {
			a {
				font-weight: $bold;
			}
		}
	}
}