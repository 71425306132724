// **
// Alert box
// **
.rating-box {
	border-radius: 4px;
	padding: $margin-md $margin;
	color: $white;
	background-color: $primary;
	text-align: left;
	.title {
		@include font(18px, 1.4, $bold);
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.btn {
			min-width: 120px;
		}
		.btn + .btn {
			margin-left: 12px;
		}
	}
}


// **
// Modal
// **
.modal-rating {
	border-width: 0;
	background-color: transparent;
	.modal-header {
		border-radius: 4px 4px 0 0;
		background-color: $primary;
		color: $white;
		padding: 16px 22px;
		position: relative;
		.title {
			@include font(26px, 1.1, $semibold);
		}
		p {
			color: rgba($white, .6);
			span {
				color: $theme-color;
				font-weight: $bold;
			}
		}
		.close {
			color: $white;
			@include font(13px, 1);
			@include underline(0,0);
			opacity: .35;
			text-shadow: none;
			margin: 0;
			display: block;
			position: absolute;
			top: 16px;
			right: 16px;
			float: none;
			transition: opacity .2s ease;
			&:hover {
				opacity: 1;
			}
		}
	}
	.modal-body, .modal-footer {
		background-color: $white;
	}
	.modal-footer {
		border-radius: 0 0 5px 5px;
		padding: 0;
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		&:before, &:after {
			display: none;
		}
		.btn {
			width: 50%;
			+.btn {
				margin-left: 0;
			}
		}
		.btn-right {
			border-radius: 0 0 4px 0;
		}
		.btn-left {
			border-radius: 0 0 0 4px;
		}
	}
	.modal-body {
		padding: 40px 22px;
		.content {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
			width: 100%;
		}

		.title {
			@include font(18px, 1.4, $bold);
			color: $primary;
		}
	}

	.rating-slider {
		.texts {
			display: flex;
			justify-content: space-between;
		}
		.text {
			text-transform: uppercase;
			@include font(12px, 1.4, $semibold);
			&.less {
				color: $danger;
			}
			&.more {
				color: $success;
			}
		}
	}


	@media (max-width: $screen-xxs-max) {
		.modal-body {
			.title br {
				display: none;
			}
		}
	}
}

// **
// Rating result card
// **
.rating--card-result {
	.headline {
		.result {
			@include font(40px, 1.1, $bold);
			color: $primary;
			span {
				@include font(20px, 1.1);
			}
		}
	}
}

// **
// Result box
// **
.rating--box-result {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: $screen-sm-max) {
		display: block;
		.rating-detail {
			margin-top: $margin;
		}
	}

	@media (max-width: $screen-xxs-max) {
		margin-bottom: 0;
	}
}


// **
// Ratings comments
// **
.ratings-comments {
	margin-top: $margin-xlg;
	margin-bottom: $margin-xlg;
	.rating--comment {
		margin-top: $margin;
		padding-bottom: $margin;
		border-bottom: 1px solid $grey-lighten;
	}

	@media (max-width: $screen-lg-max) {
		margin-top: 0;
		margin-bottom: $margin-lg;
	}
	@media (max-width: $screen-sm-max) {
		.comment-detail-rating {
			margin-top: $margin-md;
		}
	}
}

// **
// Rating comment
// **
.rating--comment {
	.rating--comment-wrapper {
		position: relative;
		padding-left: (90px + $margin-md);
	}
	.rating--comment-image {
		width: 90px;
		position: absolute;
		left: 0;
		top: 0;
	}

	// Headline
	.headline {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 90px;
		padding: $margin-sm 0;
		.title-container {
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			> * {
				flex-shrink: 0;
				max-width: 100%;
			}
			.note {
				font-style: italic;
				@include font(12px, 1.4);
			}
		}
		.rating {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			margin-left: -4px;
			margin-right: -4px;
			position: relative;
			> div, > p {
				padding-left: 4px;
				padding-right: 4px;
			}
			.value {
				@include font(16px, 1.3);
			}
			.note {
				font-style: italic;
			}
			.indicator {
				width: 200px;
				.slider.slider-horizontal {
					margin-top: 3px;
					margin-bottom: 0;
				}
			}
		}
	}

	// Type: header
	.rating--comment-wrapper[data-type="header"] {
		background-color: $table-nth-bg;
		border-radius: $radius;
	}

	// Type: body
	.rating--comment-wrapper[data-type="body"] {
		margin-top: $margin;
		@media (max-width: $screen-md-max) {
			padding-left: 0;
			margin-top: $margin-sm;
		}
	}
}

// **
// **
.rating--comment-photos {
	width: 100%;
}

// **
// Rating answer
// **
.rating--comment-answer {
	border-left: 2px solid $theme-color;
	padding: $margin-md 0 $margin-md $margin;
	margin: $margin-md 0;
	background-color: $table-nth-bg;
	.title-container {
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		> * {
			flex-shrink: 0;
			max-width: 100%;
		}
		.note {
			font-style: italic;
			@include font(12px, 1.4);
		}
	}
}