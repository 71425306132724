// General
.btn {
	text-transform: uppercase;
	@include font(14px, null);
	@media (min-width: $screen-xs-min) {
		&.btn-wider {
			padding-left: $margin-lg;
			padding-right: $margin-lg;
		}
	}
	&.btn-xs, &.btn-sm {
		text-transform: none;
		font-weight: $semibold;
		i {
			font-size: 1.1em;
		}
	}
	&.btn-noUpper {
		text-transform: none;
	}
	&.btn-noMinWidth {
		min-width: 0 !important;
	}
}

a.btn, span.btn {
	&.btn-sm {
		line-height: ($buttonHeight-sm - 2);
	}
}

// Theme
.btn-theme {
	background: $theme-color;
	border-color: $theme-color;
	color: $white;
	&:hover {
		background-color: lighten($theme-color, 6%);
		border-color: lighten($theme-color, 6%);
		box-shadow: 0 3px 16px rgba($theme-color, 0.4);
	}
	&:focus, &:active {
		box-shadow: none;
		background-color: darken($theme-color, 4%);
		border-color: darken($theme-color, 4%);
		outline: 0;
	}
}
.btn-inverse-info {
	font-weight: $semibold;
}
.btn-inverse-theme {
	background: $white;
	border-color: $formBorderColor;
	border-width: 2px;
	color: $text-color;
	text-transform: none;
	font-weight: $semibold;
	&:hover {
		border-color: $theme-color;
		box-shadow: none;
	}
	&:focus, &:active {
		box-shadow: none;
		border-color: darken($theme-color, 4%);
		outline: 0;
	}
	i {
		color: $theme-color;
	}
}
.dropbtn {
    text-transform: none;
    background: #2d4e6f;
	border-color: #2d4e6f;
	border-width: 2px;
	color: $white;
	font-weight: $semibold;
	&:hover {
		border-color: #346087;
		background-color: #346087;
		box-shadow: none;
	}
}
a.btn-share {
	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    height: 40px;

    i {
    	width: 100%;
    	margin: 7px 0 -6px 0;
    }

    div	{
    	line-height: 30px;
    	font-size: 12px;
    }

}
a.btn-inverse-theme.btn-xs {
	line-height: ($buttonHeight-xs - 4);
}

// Pure
@include CoreButton(pure, transparent, $theme-color);
.btn-pure {
	font-size: 18px;
	padding-left: 0;
	padding-right: 0;
	min-width: $FormElementHeight;
	text-align: center;
}
a.btn-pure, button.btn-pure, input.btn-pure {
	&:hover, &:focus &:active {
		box-shadow: none;
		color: $second-color;
		border-color: $formBorderColor;
	}
}
span.btn-pure {
	&:hover, &:focus &:active {
		box-shadow: none;
		cursor: auto;
	}
}

// Theme light
@include CoreButton(theme-light, rgba($theme-color, 0.05), $blue);
.btn-theme-light {
	border-color: $theme-color;
	i {
		color: $theme-color;
	}
	span + i, i + span {
		margin-left: 2px;
	}
	&:hover, &:focus &:active {
		color: $blue;
		border-color: $formBorderColor;
		i {
			color: $blue;
		}
		box-shadow: none;
	}
}

// App
@include CoreButton(app, transparent, $blue);
.btn-group {
	.btn-app {
		&:nth-child(n+2) {
			border-left: 0;
		}
	}
}
.btn-app {
	background: rgba($grey-lighten, 0.5);
	border-color: $formBorderColor;
	color: $text-color;
	font-size: 13px;
	padding: 0 8px;
	height: 26px;
	text-transform: none;
	span + i, i + span {
		margin-left: 2px;
	}
	&:hover, &:focus &:active {
		color: $blue;
		border-color: $formBorderColor;
		background: rgba($grey-lighten, 0.9);
		box-shadow: none;
	}
}
a.btn-app, button.btn-app, input.btn-app {
	line-height: 18px;
	padding-top: 4px;
	padding-bottom: 4px;
}
span.btn-app {
	&:hover, &:focus &:active {
		box-shadow: none;
		cursor: auto;
	}
}


// Facebook, VK
@include CoreButton(facebook, $facebook, $white);
@include CoreButton(vk, $vk, $white);
.btn-signinVia {
	text-transform: none;
	i {
		position: absolute;
		left: 12px;
		transform: translateY(-50%);
		top: 50%;
		display: block;
		@include font(18px, 1em);
		width: 20px;
		text-align: center;
	}
}

// dropdown button
.dropdown {
    position: relative;
    display: inline-block;
    a {
        text-decoration: none;
    }
    &-content {
        display: none;
        position: absolute;
        background-color: lighten($formBorderColor, 10%);
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        right: 0;
        margin-top: -5px;

        a {
	        color: black;
	        padding: 12px 16px;
	        text-decoration: none;
	        display: block;
		    transition: background-color .25s;

	        &:hover { 
	        	background-color: lighten($formBorderColor, 2%);
	        	//color: $white;
	        	transition: all .25s ease-in-out;
	        }
	    }
    }

    @media (max-width: $screen-xs-max) {
    	&-content {
    		max-width: 260px;
    	}
    }

    &:hover {
    	.dropdown-content {
	        display: block;
	    }
    }
}
