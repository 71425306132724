// Helped mixin
@mixin CoreIndetationSub($type, $size, $indentation)
{
	.#{$type}-xy#{$size} {
		#{$type}: $indentation;
	}
	.#{$type}-y#{$size} {
		#{$type}-top: $indentation;
		#{$type}-bottom: $indentation;
	}
	.#{$type}-x#{$size} {
		#{$type}-left: $indentation;
		#{$type}-right: $indentation;
	}
	.#{$type}-left#{$size} {
		#{$type}-left: $indentation;
	}
	.#{$type}-right#{$size} {
		#{$type}-right: $indentation;
	}
	.#{$type}-top#{$size} {
		#{$type}-top: $indentation;
	}
	.#{$type}-bottom#{$size} {
		#{$type}-bottom: $indentation;
	}
}
@mixin CoreIndetation($size, $indentation)
{
	@include CoreIndetationSub('padding', $size, $indentation);
	@include CoreIndetationSub('margin', $size, $indentation);
}



// Use
@if $module-indentation {
	// Default
	@include CoreIndetation("", $indentationDefault);

	// For each size
	$i: 1;
	@each $size in $indentationSizes 
	{
		@include CoreIndetation("-#{$size}", nth($indentationAll, $i));
		$i: $i + 1;
	}
}
