
@mixin CoreVisibility($size, $min, $max)
{
	@if $max == null {
		@media (min-width: $min) {
			.visible-#{$size} {
				display: block;
			}
			table.visible-#{$size} {
				display: table;
			}
			.hidden-#{$size} {
				display: none !important;
			}
		}
		@media (max-width: $min - 1) {
			.visible-#{$size} {
				display: none;
			}
		}
	}
	@else {
		@media (min-width: $min) and (max-width: $max) {
			.visible-#{$size} {
				display: block;
			}
			table.visible-#{$size} {
				display: table;
			}
			.hidden-#{$size} {
				display: none !important;
			}
		}
		@media (max-width: $min - 1), (min-width: $max + 1) {
			.visible-#{$size} {
				display: none;
			}
		}
	}
} 


// Use
@if $module-visibility {
	// Default
	.visible {
		display: block;
	}
	.hidden {
		display: none !important;
	}

	// Grid
	@each $size in $visibilitySizes
	{
		@include CoreVisibility($size, __ScreenGetMin($size), __ScreenGetMax($size));
	}
}
