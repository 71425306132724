$fontName: 'Flaticon';
$fontPath: "../fonts/flaticon/Flaticon";

/**
 * Function for font
 */
@mixin __Flaticon($name, $size: normal, $style: normal) {
  @font-face {
    font-family: $fontName;
    src: url('#{$name}.eot');
    src: url('#{$name}.eot?#iefix') format('embedded-opentype'),
    url('#{$name}.woff') format('woff'),
    url('#{$name}.ttf') format('truetype'),
    url('#{$name}.svg#Flaticon') format('svg');
    font-weight: $size;
    font-style: $style;
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: $fontName;
      src: url("#{$name}.svg#Flaticon") format("svg");
    }
  }
}


/**
 * Include font
 */
@include __Flaticon($fontPath);


/**
 * CSS classes
 */
.fi {
  display: inline-block;
  font: normal normal normal 14px/1 Flaticon;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fi:before{
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.fi-arrows:before { content: "\f100"; }
.fi-arrows-1:before { content: "\f101"; }
.fi-arrows-2:before { content: "\f102"; }
.fi-avatar:before { content: "\f103"; }
.fi-calendar:before { content: "\f104"; }
.fi-check-mark:before { content: "\f105"; }
.fi-credit-card:before { content: "\f106"; }
.fi-delete:before { content: "\f107"; }
.fi-delete-button:before { content: "\f108"; }
.fi-dog:before { content: "\f109"; }
.fi-edit:before { content: "\f10a"; }
.fi-facebook-logo:before { content: "\f10b"; }
.fi-female:before { content: "\f10c"; }
.fi-line-menu:before { content: "\f10d"; }
.fi-list:before { content: "\f10e"; }
.fi-locked-padlock-outline:before { content: "\f10f"; }
.fi-magnifying-glass:before { content: "\f110"; }
.fi-man:before { content: "\f111"; }
.fi-medical:before { content: "\f112"; }
.fi-move:before { content: "\f113"; }
.fi-music:before { content: "\f114"; }
.fi-pets:before { content: "\f115"; }
.fi-photo-camera:before { content: "\f116"; }
.fi-search:before { content: "\f117"; }
.fi-shapes:before { content: "\f118"; }
.fi-sign:before { content: "\f119"; }
.fi-signs:before { content: "\f11a"; }
.fi-star:before { content: "\f11b"; }
.fi-star-1:before { content: "\f11c"; }
.fi-vk:before { content: "\f11d"; }

$font-Flaticon-arrows: "\f100";
$font-Flaticon-arrows-1: "\f101";
$font-Flaticon-arrows-2: "\f102";
$font-Flaticon-avatar: "\f103";
$font-Flaticon-calendar: "\f104";
$font-Flaticon-check-mark: "\f105";
$font-Flaticon-credit-card: "\f106";
$font-Flaticon-delete: "\f107";
$font-Flaticon-delete-button: "\f108";
$font-Flaticon-dog: "\f109";
$font-Flaticon-edit: "\f10a";
$font-Flaticon-facebook-logo: "\f10b";
$font-Flaticon-female: "\f10c";
$font-Flaticon-line-menu: "\f10d";
$font-Flaticon-list: "\f10e";
$font-Flaticon-locked-padlock-outline: "\f10f";
$font-Flaticon-magnifying-glass: "\f110";
$font-Flaticon-man: "\f111";
$font-Flaticon-medical: "\f112";
$font-Flaticon-move: "\f113";
$font-Flaticon-music: "\f114";
$font-Flaticon-pets: "\f115";
$font-Flaticon-photo-camera: "\f116";
$font-Flaticon-search: "\f117";
$font-Flaticon-shapes: "\f118";
$font-Flaticon-sign: "\f119";
$font-Flaticon-signs: "\f11a";
$font-Flaticon-star: "\f11b";
$font-Flaticon-star-1: "\f11c";
$font-Flaticon-vk: "\f11d";

