// Sizes
$header-height: 60px;
$theme-color-bg: rgba($theme-color, 0.1);

//
// Daterange picker
//
$daterangepicker-bg-color:                   $white;
$daterangepicker-cell-hover-bg-color:        $grey-lighten;
$daterangepicker-in-range-color:             $blue;
$daterangepicker-in-range-bg-color:          $theme-color-bg;
$daterangepicker-active-color:               $white;
$daterangepicker-active-bg-color:            $theme-color;
$daterangepicker-unselected-color:           $grey;
$daterangepicker-unselected-bg-color:        $white;
// daterangepicker
$daterangepicker-padding:        $margin-xs;
$daterangepicker-border-color:   $grey-light;
$daterangepicker-border-radius:  $radius-sm;
// Controls
$daterangepicker-control-height:               32px;
$daterangepicker-control-color:                $grey;
$daterangepicker-control-border-color:         $grey-lighten;
$daterangepicker-control-border-radius:        $radius-sm;
$daterangepicker-control-active-border-color:  $second-color;
$daterangepicker-control-disabled-color:       $grey-lighten;
// Ranges
$daterangepicker-ranges-color:                $grey;
$daterangepicker-ranges-bg-color:             darken($white, 4%);
$daterangepicker-ranges-hover-bg-color: $second-color;
$daterangepicker-ranges-hover-color: $white;
$daterangepicker-ranges-hover-border-color: $second-color;