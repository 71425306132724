@mixin CoreAlertBox($name, $bg, $border, $color)
{
	.alert-#{$name} 
	{
		background: $bg;
		border-color: $border;
		color: $color;
		a {
			color: $color;
		}
	}
} 


// Use
@if $module-alert {
	.alert {
		padding: $alertPadding;
		margin: $alertMarginTop 0 $alertMarginTop;
		position: relative;
		min-height: $alertMinHeight;
		border: $alertBorderSize solid transparent;
		// Alert group
		& + .alert {
			margin-top: -#{($alertMarginBottom / 2)};
		}
		p {
			margin: $margin-sm 0;
		}
	}
	@each $type in $alertTypes {
		@include CoreAlertBox(nth($type, 1), nth($type, 2), nth($type, 3), nth($type, 4));
	}
}
