@mixin CoreForm {
	display: block;
	border: $formBorder;
	margin: 0;
	padding: 0;
	width: 100%;
	color: $formColor;
	font-weight: $formWeight;
	background: $formBackground;
	border-radius: $formRadius;
	font-size: $formFont;
	@include FontFamily();
	transition: $formTransition;
	// Focus
	&:focus, &:active {
		border-color: $formFocusBorder;
		color: $formFocusColor;
		box-shadow: $formFocusBoxShadow;
	}
}

@mixin CoreFormInput($size, $height, $padding, $align: null)
{
	.frm-inp#{$size} {
		height: $height;
		padding-right: $padding;
		padding-left: $padding;
		@if $align != null {
			text-align: $align;
		}
	}
}

@mixin CoreFormText($size, $height, $padding)
{
	.frm-text#{$size} {
		height: $height;
		padding: $padding;
	}
}

@mixin CoreFormSelect($size, $height, $indent)
{
	.frm-select#{$size} {
		height: $height;
		text-indent: $indent;
		&[multiple] {
			height: 100px;
		}
	}
}


// Use
@if $module-form {
	// Element wrap
	.frm-el-wrap
	{
		display: block;
		position: relative;
		margin-top: $margin-xs;
		margin-bottom: $margin-md;
		&.frm-el-wrap-lg {
			margin-top: $margin-md;
			margin-bottom: $margin;
		}
	}
	.frm-container {
		display: block;
	}


	// Form element
	.frm-el {
		@include CoreForm();
	}
	.frm-el-inline {
		@include CoreForm();
		display: inline-block;
	} 


	// Input
	@include CoreFormInput('', $FormElementHeight, $FormElementPadding);
	@include CoreFormInput('-lg', $FormElementHeight-lg, $FormElementPadding-lg);
	@include CoreFormInput('-md', $FormElementHeight-md, $FormElementPadding-md);
	@include CoreFormInput('-sm', $FormElementHeight-sm, $FormElementPadding-sm, center);
	@include CoreFormInput('-xs', $FormElementHeight-xs, $FormElementPadding-xs, center);
	@include CoreFormInput('-xxs', $FormElementHeight-xxs, $FormElementPadding-xxs, center);


	// Textarea
	@include CoreFormText('', $FormTextHeight, $FormTextPadding);
	@include CoreFormText('-lg', $FormTextHeight-lg, $FormTextPadding-lg);
	@include CoreFormText('-md', $FormTextHeight-md, $FormTextPadding-md);
	@include CoreFormText('-sm', $FormTextHeight-sm, $FormTextPadding-sm);
	@include CoreFormText('-xs', $FormTextHeight-xs, $FormTextPadding-xs);
	@include CoreFormText('-xxs', $FormTextHeight-xxs, $FormTextPadding-xxs);


	// Select
	@include CoreFormSelect('-lg', $FormElementHeight-lg, 10px);
	@include CoreFormSelect('', $FormElementHeight, 6px);
	@include CoreFormSelect('-md', $FormElementHeight-md, 6px);
	@include CoreFormSelect('-sm', $FormElementHeight-sm, 6px);
	@include CoreFormSelect('-xs', $FormElementHeight-xs, 4px);
	@include CoreFormSelect('-xxs', $FormElementHeight-xxs, 4px);


	// Label
	.frm-label {
		font-weight: $formLabelWeight;
		display: block;
		padding-bottom: 4px;
		@include font($formLabelFont);
		&.text-bold {
			font-weight: $bold;
		}
		span {
			display: block;
		}
		i {
			color: $theme-color;
		}
		// Link
		a {
			color: $theme-color;
			@include underline(1,0);
		}
		input {
			display: inline-block;
			margin-right: 4px;
		}
	}
}
