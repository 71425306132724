.dt-time-slots {
  margin-top: 30px;

  .section-container {
    margin-top: 10px;
    .section-title {
      padding: 4px 0;
      color: $second-color;
      font-weight: $bold;
    }
  }

  .section {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 6px -6px;

    + .section-title {
      margin-top: 30px;
    }

    .item {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 6px;

      &-content {
        display: block;
        border-radius: $radius-md;
        border: 1px solid $grey-lighten;
        background-color: $white;
        color: $second-color;
        text-align: center;
        padding: 10px 10px;
      }
      a.item-content {
        @include underline(0,0);
        transition: all $fast ease;
        cursor: pointer;
        &:hover, &.selected {
          background-color: $success;
          color: $white;
          border-color: $success;
        }
      }

      span.item-content {
        color: $grey-light;
        border-color: $grey-lighten;
        background-color: #f6f6f6;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .section {
      .item {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }
}

.collapse-able {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  i[data-arrow] {
    transition: transform .25s ease;
    color: $primary;
  }
  &[data-show="false"] {
    i[data-arrow] {
      transform: rotate(180deg);
    }
  }
}

.dt-date-slider {
  .month {
    @include font(14px, 1.3, $bold);
    text-align: center;
    margin-bottom: 4px;
    text-transform: uppercase;
  }
  .dates {
    display: flex;
    align-items: center;
    justify-content: space-around;
    > p {
      padding: 0 6px;
      @include font(18px, 1.3, $bold);
      > span {
        display: block;
      }
    }
    .before, .after {
      .day-name {
        margin-top: 2px;
        opacity: .8;
        font-weight: normal;
        font-size: smaller;
      }
    }
    .today {
      .day {
        font-size: 28px;
      }
      .day-name {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
}