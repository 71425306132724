/*! jQuery UI - v1.12.1 - 2019-12-09
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=base&cornerRadiusShadow=8px&offsetLeftShadow=0px&offsetTopShadow=0px&thicknessShadow=5px&opacityShadow=30&bgImgOpacityShadow=0&bgTextureShadow=flat&bgColorShadow=666666&opacityOverlay=30&bgImgOpacityOverlay=0&bgTextureOverlay=flat&bgColorOverlay=aaaaaa&iconColorError=cc0000&fcError=5f3f3f&borderColorError=f1a899&bgTextureError=flat&bgColorError=fddfdf&iconColorHighlight=777620&fcHighlight=777620&borderColorHighlight=dad55e&bgTextureHighlight=flat&bgColorHighlight=fffa90&iconColorActive=ffffff&fcActive=ffffff&borderColorActive=003eff&bgTextureActive=flat&bgColorActive=007fff&iconColorHover=555555&fcHover=2b2b2b&borderColorHover=cccccc&bgTextureHover=flat&bgColorHover=ededed&iconColorDefault=777777&fcDefault=454545&borderColorDefault=c5c5c5&bgTextureDefault=flat&bgColorDefault=f6f6f6&iconColorContent=444444&fcContent=333333&borderColorContent=dddddd&bgTextureContent=flat&bgColorContent=ffffff&iconColorHeader=444444&fcHeader=333333&borderColorHeader=dddddd&bgTextureHeader=flat&bgColorHeader=e9e9e9&cornerRadius=3px&fwDefault=normal&fsDefault=1em&ffDefault=Arial%2CHelvetica%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
	display: none;
}

.ui-helper-hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.ui-helper-reset {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	line-height: 1.3;
	text-decoration: none;
	font-size: 100%;
	list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
	content: "";
	display: table;
	border-collapse: collapse;
}

.ui-helper-clearfix:after {
	clear: both;
}

.ui-helper-zfix {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0;
	filter: Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
	z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
	cursor: default !important;
	pointer-events: none;
}


/* Icons
----------------------------------*/
.ui-icon {
	display: inline-block;
	vertical-align: middle;
	margin-top: -.25em;
	position: relative;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
}

.ui-widget-icon-block {
	left: 50%;
	margin-left: -8px;
	display: block;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ui-datepicker {
	width: 100%;
	display: block; // default inline
	.ui-datepicker-header {
		position: relative;
		padding: 12px 2.5rem;
	}

	.ui-datepicker-prev, .ui-datepicker-next {
		position: absolute;
		top: 50%;
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		transform: translateY(-50%);
		color: $white;
		text-align: center;
		border-radius: $radius-md;
		background-color: darken($theme-color, 5%);

		&.ui-state-disabled {
			opacity: .7;
			pointer-events: none;
		}

		&-hover, &:hover {
			color: $primary;
			cursor: pointer;
			background-color: $white;
		}
	}

	.ui-datepicker-prev {
		left: .5rem;
	}

	.ui-datepicker-next {
		right: .5rem;
	}

	.ui-datepicker-title {
		margin: 0 2.3em;
		line-height: 1.8em;
		text-align: center;

		select {
			font-size: 1em;
			margin: 1px 0;

			&.ui-datepicker-month, &.ui-datepicker-year {
				width: 45%;
			}
		}
	}

	table {
		width: 100%;
		font-size: .9em;
		border-collapse: collapse;
		margin: 0 0 .4em;

		th {
			padding: 10px .2em;
			text-align: center;
			font-weight: bold;
			border: 0;
		}

		td {
			border: 0;
			padding: 2px;

			span, a {
				display: block;
				padding: 10px .2em;
				text-align: right;
				text-decoration: none;
			}
		}
	}

	.ui-datepicker-buttonpane {
		background-image: none;
		margin: .7em 0 0 0;
		padding: 0 .2em;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;

		button {
			float: right;
			margin: .5em .2em .4em;
			cursor: pointer;
			padding: .2em .6em .3em .6em;
			width: auto;
			overflow: visible;

			&.ui-datepicker-current {
				float: left;
			}
		}
	}

	// **
	// Calendar content
	// **
	.ui-datepicker-calendar {
		.ui-state-default,
		.ui-widget-content .ui-state-default,
		.ui-widget-header .ui-state-default,
		.ui-button,
			/* We use html here because we need a greater specificity to make sure disabled
			works properly when clicked or hovered */
		html .ui-button.ui-state-disabled:hover,
		html .ui-button.ui-state-disabled:active {
			border-radius: $radius-md;
			border: 1px solid $grey-lighten;
			background-color: $white;
			color: $second-color;
			text-align: center;
		}


		.ui-state-default a,
		.ui-state-default a:link,
		.ui-state-default a:visited,
		a.ui-button,
		a:link.ui-button,
		a:visited.ui-button,
		.ui-button {
			color: $second-color;
			text-decoration: none;
		}

		.ui-state-hover,
		.ui-widget-content .ui-state-hover,
		.ui-widget-header .ui-state-hover,
		.ui-state-focus,
		.ui-widget-content .ui-state-focus,
		.ui-widget-header .ui-state-focus,
		.ui-button:hover,
		.ui-button:focus {
			//border: 1px solid #cccccc;
			//background: #ededed;
			//font-weight: normal;
			//color: #2b2b2b;
		}

		.ui-state-hover a,
		.ui-state-hover a:hover,
		.ui-state-hover a:link,
		.ui-state-hover a:visited,
		.ui-state-focus a,
		.ui-state-focus a:hover,
		.ui-state-focus a:link,
		.ui-state-focus a:visited,
		a.ui-button:hover,
		a.ui-button:focus {
			//color: #2b2b2b;
			//text-decoration: none;
		}

		.ui-visual-focus {
			box-shadow: 0 0 3px 1px $primary;
		}

		.ui-state-active,
		.ui-widget-content .ui-state-active,
		.ui-widget-header .ui-state-active,
		a.ui-button:active,
		.ui-button:active,
		.ui-button.ui-state-active:hover {
			border: 1px solid $success;
			background: $success;
			font-weight: normal;
			color: $white;
		}

		.ui-icon-background,
		.ui-state-active .ui-icon-background {
			border: #003eff;
			background-color: #ffffff;
		}

		.ui-state-active a,
		.ui-state-active a:link,
		.ui-state-active a:visited {
			color: #ffffff;
			text-decoration: none;
		}


		/* Interaction Cues
		----------------------------------*/
		.ui-state-highlight,
		.ui-widget-content .ui-state-highlight,
		.ui-widget-header .ui-state-highlight {
			border: 1px solid $primary;
			background: rgba($primary, 0.1);
			color: $primary;
		}

		.ui-state-checked {
			border: 1px solid $success;
			background: $success;
			color: $white;
		}

		.ui-state-highlight a,
		.ui-widget-content .ui-state-highlight a,
		.ui-widget-header .ui-state-highlight a {
			color: #777620;
		}

		.ui-state-error,
		.ui-widget-content .ui-state-error,
		.ui-widget-header .ui-state-error {
			border: 1px solid #f1a899;
			background: #fddfdf;
			color: #5f3f3f;
		}

		.ui-state-error a,
		.ui-widget-content .ui-state-error a,
		.ui-widget-header .ui-state-error a {
			color: #5f3f3f;
		}

		.ui-state-error-text,
		.ui-widget-content .ui-state-error-text,
		.ui-widget-header .ui-state-error-text {
			color: #5f3f3f;
		}

		.ui-priority-primary,
		.ui-widget-content .ui-priority-primary,
		.ui-widget-header .ui-priority-primary {
			font-weight: bold;
		}

		.ui-priority-secondary,
		.ui-widget-content .ui-priority-secondary,
		.ui-widget-header .ui-priority-secondary {
			opacity: .7;
			filter: Alpha(Opacity=70); /* support: IE8 */
			font-weight: normal;
		}

		.ui-state-disabled {
			> .ui-state-default {
				color: $grey-light;
				border-color: $grey-lighten;
				background-color: #f6f6f6;
				cursor: not-allowed;
			}
		}
	}
}


/* Component containers
----------------------------------*/
.ui-widget {
	font-family: $font;
	font-size: $font-size;

	.ui-widget {
		font-size: $font-size;
	}

	input, select, textarea, button {
		font-family: $font;
		font-size: $font-size;
	}
}

.ui-widget-content a {
	color: $link-color;
}

.ui-widget-header {
	background: $theme-color;
	color: $white;
	font-weight: bold;
	border-radius: $radius-md;

	a {
		color: $white;
	}
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
	background: #aaaaaa;
	opacity: .3;
	filter: Alpha(Opacity=30); /* support: IE8 */
}

.ui-widget-shadow {
	-webkit-box-shadow: 0px 0px 5px #666666;
	box-shadow: 0px 0px 5px #666666;
}



