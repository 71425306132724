.text-theme {
	color: $theme-color;
}

/**
 * Typography
 */
.main-title {
	@include font(28px, 1.10em, $semibold);
	color: $theme-color;
	&.blue {
		color: $second-color;
	}
	&.small {
		@include font(21px, 1.1em, $semibold);
	}
}

.sub-title {
	@include font(21px, 1.10em, $normal);
	color: $second-color;
}

.sub-sub-title {
	@include font(19px, 1.2em, $bold);
	color: $second-color;
	&.line-title {
		position: relative;
		padding-bottom: 8px;
		&:after {
			position: absolute;
			display: block;
			content: "";
			left: 0;
			bottom: 0;
			height: 2px;
			width: 40px;
			background: $theme-color;
		}
	}
}

.sub-sub-sub-title {
	@include font(16px, 1.3em, $bold);
	color: $text-color;
	&.blue {
		color: $second-color;
	}
}

.hr {
	margin: $margin 0;
	border-bottom: 1px solid $white;
	border-top: 1px solid #ddd;
	@include font(0em);
	overflow: hidden;
	height: 0;
}

// Colors
.male {
	color: $male;
}

.female {
	color: $female;
}

.bold {
	font-weight: $bold;
}

// Link
.link-default {
	color: $theme-color;
	@include underline(1, 0);
	font-weight: $semibold;
	&:hover {
		color: lighten($theme-color, 6%);
	}
	&:focus, &:active {
		color: darken($theme-color, 4%);
	}
}

.link-secondary {
	color: $text-color;
	@include underline(1, 0);
	font-weight: $semibold;
	&:hover {
		color: lighten($text-color, 6%);
	}
	&:focus, &:active {
		color: darken($text-color, 4%);
	}
}

.link-tooltip {
	@include underline(1, 0);
	cursor: help;
}

// Paw list
.dog-paw-list {
	li {
		position: relative;
		padding-left: 30px;
		margin: $margin-sm 0;

		&:before {
			@include FontFamily("custom");
			content: $font-Flaticon-dog;
			color: $theme-color;
			position: absolute;
			display: block;
			left: 2px;
			top: -1px;
			@include font(18px, 1em);
		}
	}
}

// Number list
.number-list {
	counter-reset: number-list;
	li {
		position: relative;
		padding-left: 34px;
		margin: $margin-md 0;

		&:before {
			content: counter(number-list);
			counter-increment: number-list;
			color: $theme-color;
			border: 2px solid $second-color;
			border-radius: 100%;
			position: absolute;
			display: block;
			left: 0;
			top: -6px;
			text-align: center;
			width: 24px;
			height: 24px;
			@include font(14px, 26px, $semibold);
		}
	}
}

// Color indicator
.color-indicator {
	display: inline-block;
	width: 18px;
	position: relative;
	span {
		display: block;
		border-radius: 100%;
		width: 18px;
		height: 18px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

/**
 * General stuffs
 */
.content-top-search {
	@media (max-width: $screen-xs-max) {
		margin-bottom: $margin-sm;
	}
}

// Grid types
.grid-select {
	> p {
		float: left;
		@include font(14px, $FormElementHeight);
		+ p {
			margin-left: 8px;
		}
	}
	.grid-item {
		font-size: 18px;
		a {
			display: block;
			color: $grey-light;
			@include underline(0, 0);
			transition: color $fast ease;
			&:hover {
				color: $second-color;
			}
		}
		&.active a {
			color: $theme-color;
		}
	}
}

// Table default
.tbl-default, .tbl-default-container > table {
	width: 100%;
	background: $white;
	border-collapse: collapse;
	font-weight: $semibold;
	td, th {
		border-bottom: 1px solid $table-line;
		padding: 12px 12px;
	}
	tbody {
		tr:nth-child(2n + 1) td {
			background: $table-nth-bg;
		}
	}

	// Gender
	.td-gender {
		font-size: 18px;
	}

	// Action
	.td-action {
		width: 40px;
		text-align: center;
		@include font(20px, 1.1em);
		a {
			display: inline-block;
			color: $grey-light;
			@include underline(0, 0);
			&:hover {
				color: $red;
			}
		}
	}

	// Foot
	tfoot {
		font-size: 21px;
		font-weight: $semibold;
		td, th {
			border-bottom-width: 0;
			padding-top: $margin;
			padding-bottom: $margin;
			.state {
				font-weight: $bold;
			}
		}
	}

	// Link
	a {
		color: $text-color;
		@include underline(1, 0);
	}
}

.hospitalization-left-list {
	margin-bottom: $margin-md;
	> div {
		margin-top: $margin-sm;
		margin-bottom: $margin-sm;
	}
	.title {
		color: $second-color;
		@include font(1.1em, 1.3em, $bold);
		margin-bottom: 2px;
	}
	.value {
		color: $text-color;
		&.value-highlighted {
			font-weight: $bold;
			color: $red;
		}
	}
}

// Tbl editable
.tbl-editable {
	.cell-editable {
		position: relative;
		padding-right: 30px;
		.link-editable {
			position: absolute;
			display: block;
			color: $grey-light;
			@include font(16px, 30px);
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border-radius: $radius;
			width: 30px;
			text-align: center;
			&:hover {
				color: $white;
				background: $theme-color;
			}
		}
	}
}

// Recommend item
.recommend-item {
	margin-top: $margin;
	.title {
		color: $theme-color;
		@include font(16px, $line-height, $bold);
		position: relative;
		padding-left: 28px;
		i {
			display: block;
			@include font(22px, 1em);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}
	.content {
		p, table {
			margin: $margin-sm 0 $margin-md;
		}
	}
	@media (max-width: $screen-xs-max) {
		.responsive-table {
			width: 500px;
		}
	}
}

// Rater
.rater {
	color: $grey-light;
	@include font(20px, 1em);
	.full {
		color: #ecc408;
	}
	i + i {
		margin-left: $margin-xs;
	}
}

// User thumb
.user-thumb {
	position: relative;
	img {
		border-radius: $radius;
	}
	.overlay {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		color: $white;
		@include gradient(transparent, rgba($black, 0.7), rgba($black, 0.7));
		padding: $margin ($margin-sm + 4) $margin-sm;
		border-radius: 0 0 $radius $radius;
		&.overlay-edit {
			padding-right: ($margin-sm + 30);
		}
	}
	.user-thumb-btn-fixed {
		position: absolute;
		left: $margin-sm;
		top: $margin-sm;
	}
	.name {
		@include font(26px, 1.2em, $semibold);
		color: $white;
		display: block;
	}
	.link-edit {
		position: absolute;
		right: $margin-sm;
		bottom: $margin-sm;
		@include font(16px, 30px);
		color: $white;
		border-radius: $radius;
		text-align: center;
		width: 30px;
		&:hover {
			background: $theme-color;
		}
	}
}

// Line
.line, .line-default, .line-bold {
	border-bottom: 1px solid $formBorderColor;
	height: 1px;
	clear: both;
	@include font(0em);
}

.line-default {
	margin: $margin-md 0 $margin;
}

.line-bold {
	border-bottom-width: 2px;
	border-bottom-color: $grey-lighten;
}

// Plain list
.plain-list {
	li {
		padding: $margin-xxs 0;
	}
}

// State
.state {
	font-weight: $semibold;
	&.state-success {
		color: $success;
	}
	&.state-danger {
		color: $danger;
	}
	&.state-info {
		color: $primary;
	}
	&.state-warning {
		color: $warning;
	}
	&.state-theme {
		color: $theme-color;
	}
	&.state-grey {
		color: $grey-light;
	}
}

// Table amount
.tbl-amount-summary {
	@include font(16px, 1.3em, $bold);
	td, th {
		padding: 6px 6px;
	}
	.state {
		font-weight: $bold;
	}
}

// Progressbar
.progressbar {
	height: 6px;
	border-radius: $radius-sm;
	background-color: $grey-lighten;
	margin: ($margin-xs + 1) 0;
	min-width: 130px;
	> div {
		background: $theme-color;
		float: left;
		max-width: 100%;
		height: inherit;
		border-radius: $radius-sm;
		box-shadow: 0 0 12px rgba($theme-color, 0.6);
	}
	+ .progressbar-text {
		margin-left: $margin-sm;
	}
}

// Pagenavi
.pagenavi {
	text-align: center;
	ul {
		@include font(0em);
	}
	li {
		display: inline-block;
		@include font(16px, 30px);
		margin: $margin-xxs;
	}
	a {
		display: block;
		padding-top: 2px;
		border-radius: $radius;
		&.arrow {
			background: $theme-color;
			color: $white;
			min-width: 32px;
			@include underline(0, 0);
			&:hover {
				background: $second-color;
			}
		}
		&.page {
			min-width: 26px;
			@include underline(0, 1);
			color: $grey;
		}
	}
	.active a.page {
		text-decoration: underline;
		color: $theme-color;
		font-weight: $semibold;
	}
}

/**
 * Box
 */
.box {
	background: $white;
	box-shadow: 0 4px 14px rgba($black, 0.1);
	border-radius: $radius-sm;

	&.box-responsive {
		@media (max-width: $screen-xs-max) {
			position: relative;
			margin-left: (-$containerIndent);
			margin-right: (-$containerIndent);
		}
	}

	// Header
	.box-header {
		padding: $margin-md $margin-md;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $grey-lighten;
		.box-header--title {
			display: flex;
			align-items: center;
			@include font(18px, 1.2em, $semibold);
			i {
				color: $theme-color;
				margin-right: $margin-sm;
			}
		}
		.box-header--more {
			@include font(12px, 1.3em);
			margin-left: $margin-md;
		}
	}

	// Footer
	.box-footer {
		padding: $margin-md $margin-md;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top: 2px solid $grey-lighten;
	}

	&.box-default, .box-content {
		padding: $margin-md;
	}

	// Cat
	&.box-cat {
		position: relative;
		margin-bottom: 60px;
		.box-cat-img {
			position: absolute;
			display: block;
			left: 100%;
			bottom: 0;
			transform: translateY(60px) translateX(-120px);
		}
		@media (max-width: $screen-sm-max) {
			margin-bottom: 0;
			.box-cat-img {
				display: none;
			}
		}
	}

	// Social
	&.box-social {
		padding-left: ($margin-md + 72);
		i {
			display: block;
			position: absolute;
			left: $margin-md;
			top: 50%;
			width: 72px;
			text-align: center;
			transform: translateY(-50%);
			@include font(40px, 1em);
			&.facebook {
				color: $facebook;
			}
			&.vk {
				color: $vk;
			}
		}
		.line-title {
			padding-bottom: 4px;
		}
	}

	// Image
	&.box-image {
		border-radius: 0 0 $radius-sm $radius-sm;
		.image {
			@include font(0em);
			background: rgba($black, 0.06);
			text-align: center;
			position: relative;
			overflow: hidden;
			img {
				max-height: 400px;
				width: auto;
			}
			.image-navi {
				background-color: rgba($black, 0.2);
				top: 0;
				bottom: 0;
				display: block;
				position: absolute;
				width: 50px;
				color: rgba($white, 0.8);
				@include underline(0, 0);
				@include font(30px, 1em);
				transition: all $fast ease;
				&:hover {
					background-color: rgba($black, 0.4);
					color: $white;
				}
				i {
					position: absolute;
					top: 50%;
					display: block;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
				}
				&.image-navi-prev {
					left: -50px;
					i {
						margin-left: -3px;
					}
				}
				&.image-navi-next {
					right: -50px;
					i {
						margin-left: 3px;
					}
				}
			}
			&:hover .image-navi {
				&.image-navi-prev {
					left: 0;
				}
				&.image-navi-next {
					right: 0;
				}
			}
		}
	}
}

// **
// Pet box
// **
.common--pet-box {
	display: flex;
	&.common--pet-box--list {
		border-bottom: 1px solid $grey-lighten;
	}

	// Thumb
	.thumb {
		width: 34%;
		padding: 3px;
	}

	// Right
	.content {
		width: 66%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: ($margin-sm + 2) ($margin-sm + 2) $margin-sm 0;
		.content_indent {
			margin-left: ($margin-sm + 2);
		}
	}

	// Top
	.content--top {
		.title {
			color: $second-color;
			@include font(20px, 1.1em, $semibold);
			margin-bottom: $margin-xs;
			i {
				margin-left: $margin-xxs;
				font-size: 16px;
			}
		}
		p {
			@include font(13px, 1.3em);
		}
	}

	// Center
	.content--center {
		margin: ($margin-sm + 2) 0;
	}

	// Bottom
	.content--bottom {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 4px;
	}

	// Notify
	.pet-notify {
		background: darken($main-bg, 1%);
		padding: $margin-sm $margin-sm $margin-sm 40px;
		position: relative;
		@include font(13px, 1.3em);
		.pet-notify--text {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		i {
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			text-align: center;
			@include font(18px, 1em);
			color: $second-color;
		}
		+ .pet-notify {
			margin-top: $margin-xs;
		}
	}

	// Responsive
	@media (max-width: $screen-xs-max) {
		position: relative;
		display: block;
		.thumb {
			float: none;
			width: 62px;
			height: 62px;
			position: absolute;
			padding: 0;
			left: $margin-sm;
			top: $margin-sm;
			img {
				border-radius: 100%;
			}
		}
		.content {
			width: 100%;
			padding: $margin-sm;
			.content--top {
				padding-left: 62px;
				padding-top: 8px;
				min-height: 62px;
				margin-left: $margin-sm;
			}
		}
		.title {
			@include font(18px, 1.2em);
			margin-bottom: $margin-xs;
			i {
				font-size: 16px;
			}
		}
	}
}

.common--search-results {
	padding-left: $margin-md;
	padding-right: $margin-md;
	.common--pet-box--list {
		position: relative;
		margin-left: -$margin-md;
		margin-right: -$margin-md;
	}
}

.common--search-result-item {
	padding-top: $margin-md;
	padding-bottom: $margin-md;

	+ .common--search-result-item {
		border-top: 1px solid $grey-lighten;
	}
}

// **
// Inline parameters
// **
.common--inline-parameters {
	display: flex;
	@include font(13px, 1.3em, $semibold);
	li {
		margin-top: $margin-sm;
		margin-right: $margin-md;
		display: flex;
		align-items: center;
		i {
			align-self: flex-start;
			margin-right: $margin-xs;
			opacity: 0.7;
		}
		a {
			color: $text-color;
			@include underline(1,0);
			&[data-highlight="0"] {
				@include underline(0,1);
			}
		}
	}
}

// **
// Search result (with patient)
// **
.common--search-result-item--with-patient {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	padding-top: ($margin-sm + 3);
	padding-bottom: ($margin-sm + 3);

	// Thumb
	.thumb {
		width: 50px;
		a {
			display: block;
		}
		img {
			border-radius: 100%;
		}
		i {
			border-radius: 100%;
			border: 1px solid $grey-lighten;
			color: darken($grey-light, 10%);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			@include font(20px, 1em);
		}
	}

	// Right
	.content {
		width: calc(100% - 50px);
	}
}

/**
 * List of clinics
 */
.clinic-list {
	height: 500px;
	text-align: left;
	.clinic-item {
		background: $white;
		margin-top: 6px;
		margin-bottom: 6px;
		border-radius: $radius-md;
		position: relative;
		border-bottom: 3px solid $grey-lighten;
		transition: all .5s ease;

		&:hover {
			box-shadow: 0 0 24px rgba($second-color, 0.1) inset;
			border-bottom-color: $second-color;
		}

		&--as-link:hover {
			cursor: pointer;
		}

		// container with padding for content
		.clinic-item--content {
			padding: ($margin-md - 2) $margin-md;
		}

		// Name of a clinic
		.title {
			@include font(16px, 1.3em, $bold);
			color: $second-color;
			margin-bottom: $margin-xs;
		}

		// Container for image
		.image-container {
			border-radius: $radius-md $radius-md 0 0;
			height: 120px;
			position: relative;
			background-color: $grey-light;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			.title-container {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				background-color: rgba($white, 0.85);
				padding: 10px 10px 8px;
				.title {
					margin: 0;
				}
			}
		}

		// Address
		.clinic-item--ico-content {
			padding-left: 18px;
			position: relative;
			span {
				@include font(13px, 1.4, $semibold);
			}
			i {
				color: $theme-color;
				position: absolute;
				display: block;
				left: 0;
				top: 1px;
			}
			+ .clinic-item--ico-content {
				margin-top: $margin-sm;
			}
		}

		.btns {
			display: flex;
			align-items: center;
			position: relative;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-left: (-$margin-xs);
			margin-right: (-$margin-xs);
			> * {
				padding-left: $margin-xs;
				padding-right: $margin-xs;
			}
			.btn-theme {
				min-width: 0;
			}
			.btn-sm {
				font-size: 12px;
				text-transform: uppercase;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.deselect {
			display: none;
		}
		.btn {
			.active {
				display: none;
			}
			span > span {
				margin: 0 !important;
			}
		}
		&.active {
			border-bottom-color: $link-color;
			.deselect {
				display: block;
			}
			.btn.btn-select {
				background-color: $theme-color;
				color: $white;
				i {
					color: $white;
				}
				.inactive {
					display: none;
				}
				.active {
					display: inline-block;
				}
			}
		}
	}
}

.map-container {
	height: 500px;
}

/**
 * Pet box
 */
.pets-grid {
	.item {
		padding-bottom: ($margin-sm * 2);
	}
	// Responsive
	@media (min-width: $screen-lg-min) {
		&.pets-grid-one-per-line .box-pet {
			.right {
				width: 56%;
			}
			.thumb {
				width: 44%;
			}
		}
		&.pets-grid-full {
			.box-pet .right {
				position: relative;
				.btn-wrap {
					margin: 0;
					position: absolute;
					right: $margin-md;
					top: $margin-md;
				}
			}
			&.pets-grid-one-per-line .box-pet {
				.right {
					width: 65%;
				}
				.thumb {
					width: 35%;
				}
			}
		}
	}
}

.box-pet {
	display: flex;
	// Thumb
	.thumb {
		width: 45%;
		padding: 3px;
		img {
			border-radius: $radius-sm 0 0 $radius-sm;
		}
	}

	// Right
	.right {
		width: 55%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: $margin-md $margin-md $margin-md 0;
		.box-pet_top {
			margin-left: $margin-md;
			position: relative;
			display: flex;
			justify-content: space-between;

			.btn-share {
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	// Title
	.title {
		color: $second-color;
		@include font(26px, 1.1em, $semibold);
		margin-bottom: $margin-sm;
		i {
			margin-left: $margin-xxs;
			font-size: 24px;
		}
	}

	// Notify
	.pet-notify {
		background: $main-bg;
		padding: $margin-sm $margin-sm $margin-sm 40px;
		position: relative;
		margin-top: $margin-md;
		i {
			position: absolute;
			display: block;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 40px;
			text-align: center;
			@include font(18px, 1em);
			color: $second-color;
		}
		+ .pet-notify {
			margin-top: $margin-xs;
		}
	}

	.buttons-wrapper {
		.btn {
			padding: 0 11px;

			i+span {
				margin-left: 2px;
			}
		}
	}

	// Button
	.btn-wrap {
		margin-top: $margin-md;
	}

	// Responsive
	@media (max-width: $screen-xs-max) {
		position: relative;
		display: block;
		.thumb {
			float: none;
			width: 62px;
			height: 62px;
			position: absolute;
			padding: 0;
			left: $margin-sm;
			top: $margin-sm;
			img {
				border-radius: $radius-sm;
			}
		}
		.right {
			width: 100%;
			padding: $margin-sm;
			.box-pet_top {
				padding-left: 62px;
				padding-top: 8px;
				min-height: 62px;
				margin-left: $margin-sm;
			}
		}
		.title {
			@include font(22px, 1.1em);
			margin-bottom: $margin-xs;
			i {
				font-size: 20px;
			}
		}
		.buttons-wrapper {
			margin-top: 16px;
		}
	}

	@media (max-width: $screen-sm-max) {
		.buttons-wrapper {
			i {
				display: block;
				padding-top: 10px;
			}
			.btn {
				line-height: 21px;
				padding: 0 8px;
			}

			.btn-inverse-info {
				line-height: 24px;
			}
		}
	}

	@media (min-width: $screen-sm-max) {
		.buttons-wrapper {
			.btn-inverse-info {
				line-height: 51px;
			}
		}
		
	}

	@media (min-width: $screen-xs-max) {
		.buttons-wrapper {
			margin-left: 16px;	
		}
		.pet-notify {
			margin: 16px 0 16px 16px;
		}
	}
}

// Search pet box
.box-pet-search {
	padding-top: 0;
	// Top
	.top {
		border-radius: $radius-sm $radius-sm 0 0;
		background: $primary;
		color: $white;
		padding: $alertPadding;
		font-weight: $semibold;
	}

	// Title
	.title {
		@include font(24px, 1.2em);
		font-weight: $bold;
		margin-bottom: $margin-md;
	}

	// Thumb
	.thumb {
		float: left;
		width: 20%;
		padding: 8px 0 8px 8px;
		img {
			border-radius: $radius-sm 0 0 $radius-sm;
		}
	}

	// Right
	.right {
		float: left;
		width: 80%;
		padding: $margin-md $margin-md;
	}
	.content {
		padding: $margin-md $margin-md;
	}
	.link-list {
		li {
			margin: $margin-sm 0;
		}
		a {
			color: $theme-color;
			@include underline(1,0);
		}
	}

	// Table
	.tbl-pet-data {
		th, td {
			vertical-align: top;
		}
		td {
			@include font(15px);
			&.td-name {
				@include font(18px);
			}
		}
		ul {
			li {
				margin-bottom: $margin-sm;
				&:last-child {
					margin-bottom: 0;
				}
			}
			i {
				display: inline-block;
				width: 20px;
				text-align: center;
				color: rgba($second-color, 0.6);
			}
		}
		a:not(.btn) {
			color: $second-color;
			@include underline(0,1);
		}
	}

	// Responsive
	@media (max-width: $screen-xs-max) {
		.thumb {
			float: none;
			width: auto;
			text-align: center;
			padding: $margin-md $margin-md 0;
			img {
				max-width: 140px;
				display: inline-block;
			}
		}
		.right {
			float: none;
			width: auto;
		}
	}
}

// Notification
.notice-overview-item {
	display: block;
	border-top: 1px solid $formBorderColor;
	padding: 12px $margin-md;
	padding-left: 54px;
	position: relative;
	color: $second-color;
	&:first-child {
		border-top-width: 0;
	}

	i {
		position: absolute;
		display: block;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		color: $theme-color;
		width: 54px;
		text-align: center;
		@include font(22px, 1em);
	}

	span {
		display: block;
		+ span {
			margin-top: $margin-xxs;
		}
	}
	.title {
		font-weight: $bold;
	}
}

// Gallery list
.gallery-list {
	li {
		padding-top: $gridIndent-xxs;
		padding-bottom: $gridIndent-xxs;
	}
	a {
		display: block;
		position: relative;
		&:hover {
			&:before, &:after {
				display: block;
				position: absolute;
			}
			&:before {
				background: rgba($theme-color, 0.55);
				left: 0;
				right: 0;
				top: 0;
				content: "";
				bottom: 0;
			}
			&:after {
				left: 50%;
				top: 50%;
				transform: translateY(-50%) translateX(-50%);
				@include FontFamily("ico");
				content: "\f002";
				color: $white;
				@include font(24px, 1em);
			}
		}
	}
}

// **
// Breadcrumbs
// **
.breadcrumbs {
	margin-bottom: $margin;
	ul {
		display: flex;
		flex-wrap: wrap;
		i {
			display: inline-block;
			margin-left: $margin-sm;
		}
		li + li {
			margin-left: $margin-sm;
		}
	}
	a {
		color: $text-color;
		@include underline(1,0);
	}
}


// **
// Address
// **
.common--address {
	.address {
		font-weight: $bold;
		i {
			color: $theme-color;
			display: inline-block;
			margin-right: $margin-xs;
		}
	}
	.btn-container {
		margin-top: $margin-md;
	}
}
.common--btns-group {
	> div {
		padding-bottom: (2*$gridIndent-xs);
	}
}


// **
// Abilities list
// **
.common--abilities-list {
	.item {
		margin-bottom: $margin-md;
		display: flex;
		align-items: center;
		i {
			color: $theme-color;
			display: block;
			margin-right: $margin-xs;
			align-self: flex-start;
			font-size: 18px;
			line-height: 1em;
			width: 40px;
			text-align: center;
		}
		span {
			display: block;
		}
	}
}

// **
// Single map
// **
.common--single-map {
	width: 100%;
	height: 400px;
}

// **
// Categories
// **
.common--search-categories {
	background-color: $white;
	box-shadow: 0 0 24px rgba($black, 0.1);
	li {
		border: 1px solid $grey-lighten;
		border-bottom-width: 0;
		&:last-child {
			border-bottom-width: 1px;
		}
		&.disabled {
			a {
				cursor: not-allowed;
				color: $grey-light;
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
	a {
		display: flex;
		padding: $margin-md $margin-sm;
		color: $text-color;
		@include font(14px, 1.2em, $semibold);
		@include underline(0,0);
		transition: background-color $fast ease;
		&:hover {
			background-color: rgba($grey-lighten, 0.5);
		}
		i {
			width: 24px;
			text-align: center;
			margin-right: $margin-sm;
		}
	}

	// Active item
	li.active {
		border-left: 3px solid $blue;
		color: $blue;
		a {
			padding-left: ($margin-sm - 2);
			background-color: rgba($grey-lighten, 0.3);
			position: relative;
		}
	}
}

// Action select
.common--action-select {
	display: block;
	position: relative;
	z-index: 10;
	@include font(13px, 1.3em);
	> p {
		display: flex;
		min-width: 190px;
		align-items: center;
		justify-content: space-between;
		padding: ($margin-sm) ($margin-sm + 2);
		background: rgba($grey-lighten, 0.5);
		border: 1px solid $formBorderColor;
		color: $text-color;
		border-radius: $radius-sm;
		cursor: pointer;
		i {
			margin-left: $margin-sm;
			font-size: 80%;
		}
		&:hover {
			border-color: $blue;
			color: $blue;
		}
	}
	.list {
		display: none;
		transform: translateY(2px);
		position: absolute;
		z-index: 20;
		top: 100%;
		right: 0;
		width: 100%;
		min-width: 190px;
		background-color: $white;
		box-shadow: 0 0 24px rgba($black, 0.1);
		li {
			border: 1px solid $grey-lighten;
			border-bottom-width: 0;
			&:last-child {
				border-bottom-width: 1px;
			}
		}
		a {
			display: flex;
			padding: ($margin-sm + 4) $margin-sm;
			color: $text-color;
			@include font(13px, 1.2em, $semibold);
			@include underline(0,0);
			transition: background-color $fast ease;
			&:hover {
				background-color: rgba($grey-lighten, 0.5);
			}
			i {
				width: 18px;
				text-align: center;
				margin-right: $margin-xs;
			}
		}

		// Active item
		li.active {
			a {
				color: $theme-color;
			}
		}
	}
}

.common--action-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.common--action-select {
		margin-left: $margin-sm;
	}

	@media (max-width: $screen-sm-max) {
		flex-direction: column;
		justify-content: center;
		.common--action-select {
			margin-left: 0;
			margin-top: $margin-sm;
		}
	}
}

.doctor-row {
	div {
		word-break: break-all;
	}
}

// **
// Social links
// **
.common--social-links {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@include font(32px, 1);
	li {
		margin-right: $margin-md;
		a {
			display: block;
			color: $theme-color;
			transition: color $fast ease;
			&:hover {
				color: $blue;
			}
		}
		&.fb a {
			color: #3b5998;
		}
		&.instagram a {
			color: #c32aa3;
		}
		&.whatsapp a {
			color: #25D366;
		}
	}
}


// **
// Tags
// **
.common--tags {
	$tags--padding: 3px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-left: (-$tags--padding);
	margin-right: (-$tags--padding);
	li {
		padding: (2 * $tags--padding) $tags--padding 0;
	}
	a {
		display: block;
		background-color: $primary;
		border-radius: $radius-md;
		padding: 5px 10px 4px;
		@include font(12px, 1.3, $bold);
		@include underline(0,0);
		text-transform: uppercase;
		color: $white;
		transition: all $fast ease;
		&:hover {
			color: $white;
			background-color: $theme-color;
		}
	}
}


// **
// Contact data
// **
.common--contact-data {
	$contact-data--padding: 14px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	align-items: center;
	margin-left: (-$contact-data--padding);
	margin-right: (-$contact-data--padding);
	li {
		padding: $contact-data--padding $contact-data--padding 0;
		display: flex;
		align-items: center;
		i {
			@include font(24px, 1);
			display: block;
			color: $blue;
			margin-right: 10px;
		}
		a, .as-link {
			font-weight: $bold;
			@include underline(1,0);
			@include font(16px, 1.2);
			cursor: pointer;
			color: $blue;
			&:hover {
				color: $link-color;
			}
		}
	}

}


// **
// Hidden text
// **
.common--hidden-text {
	.value {
		display: none;
	}
	.text {
		display: block;
	}
	&.show {
		.value {
			display: block;
		}
		.text {
			display: none;
		}
	}
}