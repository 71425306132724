.select2-selection--multiple {
	background-color: #fff;
	border: $formBorder;
	border-radius: $formRadius;
	cursor: text;
	min-height: $FormElementHeight;

	.select2-selection__rendered {
		list-style: none;
		margin: 0;
		padding: 0 5px;
		width: 100%;
		.select2-search--inline {
			display: block;
			input {
				height: ($FormElementHeight - 12);
				text-indent: 4px;
			}
		}
		li {
			list-style: none;
			+ li.select2-search--inline {
				display: inline-block;
				input {
					height: 22px;
					text-indent: 0;
				}
			}
		}
	}

  .select2-selection__placeholder {
    color: $text-color;
    margin-top: 5px;
    float: left;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px;
  }

  .select2-selection__choice {
    background-color: $theme-color;
    border: 1px solid $theme-color;
    border-radius: $formRadius;
    color: $white;
    font-weight: $normal;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
  }

  .select2-selection__choice__remove {
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 4px;
    &:hover {
      color: $blue;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }

    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}


&.select2-container--disabled {
  .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
  }

  .select2-selection__choice__remove {
    display: none;
  }
}
