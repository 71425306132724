/**
 * Base variables for project
 * @author Martin Adamec
 * @url http://adamecmartin.cz
 */

/**
 * ===========================================================
 * 		1. Base parameters for using this FW
 * ===========================================================
 */
// Directory for resources
$dir 			: '..' !default;

// Screen size
$screen-xlg	: 100% !default;
$screen-lg	: 1130px !default;
$screen-md	: 960px !default;
$screen-sm	: 720px !default;
$screen-xs	: 540px !default;
$screen-xxs	: 400px !default;

// - Min
$screen-min		: 320px !default;
$screen-xlg-min	: $screen-lg + 1 !default;
$screen-lg-min	: $screen-md + 1 !default;
$screen-md-min	: $screen-sm + 1 !default;
$screen-sm-min	: $screen-xs + 1 !default;
$screen-xs-min	: $screen-xxs + 1 !default;
$screen-xxs-min	: 0 !default;

// - Max
$screen-xlg-max	: 100% !default;
$screen-lg-max	: $screen-lg !default;
$screen-md-max	: $screen-md !default;
$screen-sm-max	: $screen-sm !default;
$screen-xs-max	: $screen-xs !default;
$screen-xxs-max	: $screen-xxs !default;


// Margins
$margin-xxs	: 2px !default;
$margin-xs	: 4px !default;
$margin-sm	: 8px !default;
$margin-md	: 14px !default;
$margin		: 20px !default;
$margin-lg	: 32px !default;
$margin-xlg : 48px !default;


// Clearfix elements
$coreClearfixElements: clearfix !default;


// Font
$fontDefault	: 'Open Sans' !default;
$font			: $fontDefault, Arial, sans-serif;
$font-size		: 14px !default;
$line-height	: 1.3em !default;



/**
 * ==========================================================
 * 		2. Base colors and other settings
 * ==========================================================
 */

// Base colors
$text-color		: #444 !default;
$body-bg		: #820068 !default;
$main-bg		: #fff !default;
$link-color		: #540043 !default;
$theme-color	: #540043 !default;
$white 			: #fff;
$black 			: #000;

// Grey
$grey-lighten 	: #eee !default;
$grey-light 	: #bbb !default;
$grey 			: #727272 !default;
$grey-dark 		: #333 !default;

// Blue
$blue			: #2ba8db !default;

// Green
$green			: #84B816 !default;

// Red
$red			: #d6111a !default;

// Orange
$orange			: #f4a137 !default;

// Buttons
$default		: $grey-dark !default;
$primary		: $blue !default;
$success		: $green !default;
$danger			: $red !default;
$warning		: $orange !default;


// Font weight
$xbold		: 800 !default;
$bold		: 700 !default;
$semibold	: 600 !default;
$normal		: 400 !default;
$light		: 300 !default;
$xlight		: 300 !default;


// Radius
$radius-lg	: 12px !default;
$radius		: 10px !default;
$radius-md	: 8px !default;
$radius-sm	: 6px !default;
$radius-xs	: 4px !default;
$radius-xxs	: 2px !default;


// Fast of transition
$slow 		: 0.4s !default;
$fast		: 0.25s !default;



/**
 * ==========================================================
 * 		3. Form
 * ==========================================================
 */
// Height
$FormElementHeight-lg	: 40px !default;
$FormElementHeight		: 36px !default;
$FormElementHeight-md	: 30px !default;
$FormElementHeight-sm	: 28px !default;
$FormElementHeight-xs	: 26px !default;
$FormElementHeight-xxs	: 24px !default;
// Padding
$FormElementPadding-lg 	: 14px !default;
$FormElementPadding 	: 10px !default;
$FormElementPadding-md 	: 10px !default;
$FormElementPadding-sm 	: 8px !default;
$FormElementPadding-xs 	: 8px !default;
$FormElementPadding-xxs : 6px !default;
// Textarea
$FormTextHeight-lg		: 260px !default;
$FormTextHeight			: 160px !default;
$FormTextHeight-md		: 120px !default;
$FormTextHeight-sm		: 80px !default;
$FormTextHeight-xs		: 60px !default;
$FormTextHeight-xxs		: 40px !default;
$FormTextPadding-lg 	: 14px !default;
$FormTextPadding 		: 10px !default;
$FormTextPadding-md 	: 10px !default;
$FormTextPadding-sm 	: 8px !default;
$FormTextPadding-xs 	: 8px !default;
$FormTextPadding-xxs 	: 6px !default;



/**
 * ==========================================================
 * 		4. Addon class
 * ==========================================================
 */

// Modernizer classes
// - Touch
$touch: ".touchevents";
$no-touch: ".no-touchevents";
// - CSS collumns
$csscolumns: ".csscolumns";
$no-csscolumns: ".no-csscolumns";



/**
 * ==========================================================
 * 		5. MODULES
 * ==========================================================
 */

/**
 * Module: f
 */
$module-reset 	: true !default;

/**
 * Module: BASE
 */
$module-base 	: true !default;

/**
 * Module: GRID
 */
$module-grid 	: true !default;
// Cols
$gridCols 		: 12 !default;
$gridSizes 		: xxs xs sm md lg xlg !default;
// Indent
$gridIndent-xlg	: 24px !default;
$gridIndent-lg	: 18px !default;
$gridIndent		: 14px !default;
$gridIndent-md	: 10px !default;
$gridIndent-sm	: 8px !default;
$gridIndent-xs	: 6px !default;
$gridIndent-xxs	: 4px !default;
$gridSizesIndent : $gridIndent-xxs $gridIndent-xs $gridIndent-sm $gridIndent-md $gridIndent-lg $gridIndent-xlg !default;


/**
 * Module: CONTAINER
 * @require GRID
 */
$module-container: true !default;
$containerIndent: $gridIndent;


/**
 * Module: INDETATION
 * @require GRID
 */
$module-indentation : true !default;
$indentationSizes 	: xxs xs sm md lg xlg !default;
$indentationAll 	: $margin-xxs $margin-xs $margin-sm $margin-md $margin-lg $margin-xlg !default;
$indentationDefault : $margin !default;


/**
 * Module: FLEXBOX
 * @require GRID
 */
$module-flexbox 	: false !default;
$flexboxSizes 		: $gridSizes !default;
$flexboxIndents		: $gridSizesIndent !default;
$flexboxIndent 		: $gridIndent !default;


/**
 * Module: VISIBILITY
 * @require GRID
 */
$module-visibility 	: true !default;
$visibilitySizes 	: $gridSizes !default;


/**
 * Module: ALERT
 * @require Parameters: $primary, $danger, $success, $warning
 */
$module-alert 		: true !default;
$alertPadding 		: 8px 16px 8px 16px !default;
$alertMarginTop 	: $margin !default;
$alertMarginBottom 	: $margin !default;
$alertMinHeight 	: 40px !default;
$alertBorderSize 	: 1px !default;
// Data for types
$alertInfo 			: info $primary $primary $white !default;
$alertDanger 		: danger $danger $danger $white !default;
$alertSuccess 		: success $success $success $white !default;
$alertWarning 		: warning $warning $warning $white !default;
$alertTypes 		: $alertInfo, $alertDanger, $alertSuccess, $alertWarning !default;


/**
 * Module: BUTTON
 */
$module-button 			: true !default;
$module-buttonStyles	: true !default;
// Default
$buttonRadiusLarge 		: 20px !default;
$buttonRadius 			: $radius-md !default;
$buttonBorder 			: 1px solid transparent !default;
// - Padding
$buttonPadding 			: $margin-md !default;
$buttonPadding-lg 		: $margin-lg !default;
$buttonPadding-md 		: $margin !default;
$buttonPadding-sm 		: $margin-sm !default;
$buttonPadding-xs 		: $margin-sm !default;
$buttonPadding-xxs 		: $margin-xs !default;
// - Font size
$buttonFont 			: $font-size !default;
$buttonFont-lg 			: 16px !default;
$buttonFont-md 			: 14px !default;
$buttonFont-sm 			: 14px !default;
$buttonFont-xs 			: 12px !default;
$buttonFont-xxs 		: 12px !default;
// - Height
$buttonHeight 			: $FormElementHeight !default;
$buttonHeight-lg 		: $FormElementHeight-lg !default;
$buttonHeight-md 		: $FormElementHeight-md !default;
$buttonHeight-sm 		: $FormElementHeight-sm !default;
$buttonHeight-xs 		: $FormElementHeight-xs !default;
$buttonHeight-xxs 		: $FormElementHeight-xxs !default;

// Styles
$buttonDefault 			: $default $white $default !default;
$buttonDefaultInverse	: $default $white $white !default;

$buttonPrimary 			: $primary $white $primary !default;
$buttonPrimaryInverse	: $primary $white $white !default;

$buttonDanger 			: $danger $white $danger !default;
$buttonDangerInverse	: $danger $white $white !default;

$buttonSuccess 			: $success $white $success !default;
$buttonSuccessInverse	: $success $white $white !default;

$buttonWarning 			: $warning $white $warning !default;
$buttonWarningInverse	: $warning $white $white !default;

$buttonInfo 			: $primary $white $primary !default;
$buttonInfoInverse		: $primary $white $white !default;


/**
 * Module: FORM
 */
$module-form 		: true !default;
$formColor 			: $grey !default;
$formWeight 		: $normal !default;
$formWeight 		: $normal !default;
$formBackground 	: $white !default;
$formRadius 		: $radius !default;
$formFont 			: $font-size !default;
$formTransition 	: border-color $fast, box-shadow $fast, color $fast !default;
$formBorder 		: 1px solid $grey-light !default;
$formFocusBorder 	: $primary !default;
$formFocusColor 	: $text-color !default;
$formFocusBoxShadow : 0 2px 4px rgba($black, 0.1) inset !default;
// Label
$formLabelWeight 	: $normal !default; 
$formLabelFont 		: $font-size !default;


/**
 * Module: FONT
 */
$fontList: ('title': 'Courier New', 'base': $fontDefault) !default; 
