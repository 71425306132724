
// Label
.frm-label {
	a {
		color: $text-color;
		@include underline(1,0);
	}
}

.frm-container-search {
	position: relative;
	i {
		display: block;
		position: absolute;
		color: $formBorderColor;
		@include font(18px, 1em);
		right: 14px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.frm-container-calendar {
	position: relative;
	.frm-inp {
		padding-right: 40px;
	}
	i {
		display: block;
		position: absolute;
		color: $theme-color;
		@include font(22px, 1em);
		right: 14px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.frm-el-wrap.frm-required {
	.frm-label {
		&:before {
			content: "*";
			color: $red;
			margin-right: 4px;
			font-weight: $bold;
			display: inline-block;
		}
	}
}


/**
 * Checkbox && Radio
 */
.frm-checkbox {
	padding-left: 30px;
	margin: $margin-xs 0 $margin-xs;
	position: relative;
	display: block;
	cursor: pointer;
	.frm-label {
		padding: $margin-xxs 0;
	}
	input[type="checkbox"] {
		display: none;
	}
	&:before {
		@include FontFamily("ico");
		font-size: 24px;
		line-height: 24px;
		display: block;
		color: $grey-light;
		position: absolute;
		left: 0;
		top: -2px;
		width: 24px;
		height: 24px;
		content: "\f096";
		transition: all $fast ease;
	}
	&:hover {
		&:before {
			color: $second-color;
		}
	}
	// Checked
	&.checked:before {
		content: "\f14a";
		color: $theme-color;
	}
	&.frm-checkbox-inline {
		display: inline-block;
	}
}
.frm-checkbox-switch {
	margin: $margin-xs 0 $margin-xs;
	position: relative;
	display: block;
	cursor: pointer;
	input[type="checkbox"] {
		display: none;
	}
	.text, .switcher {
		float: left;
		display: block;
	}
	.text {
		@include font(16px, $FormElementHeight-sm, $semibold);
		color: lighten($text-color, 18%);
	}
	.switcher {
		margin: 0 8px;
		height: $FormElementHeight-sm;
		width: (2 * $FormElementHeight-sm);
		background: lightgrey;
		position: relative;
		border-radius: $FormElementHeight-sm;
		&:before {
			background: $white;
			content: "";
			position: absolute;
			display: block;
			margin-left: 2px;
			left: 0;
			top: 2px;
			bottom: 2px;
			transition: left $fast ease;
			width: ($FormElementHeight-sm - 4);
			height: ($FormElementHeight-sm - 4);
			border-radius: 100%;
		}
	}
	.switcher-small {
		height: 20px;
		width: 40px;
		&:before {
			width: 16px;
			height: 16px;
		}
	}
	&.checked {
		.switcher:before {
			left: 50%;
		}
		.switcher-small:before {
			left: 50%;
		}
		.text[data-checked="1"] {
			color: $second-color;
		}
		.switcher{
			background: $theme-color;
		}
	}
	&:not(.checked) {
		.text[data-checked="0"] {
			color: $second-color;
		}
	}
}

// readonly/disabled
.frm-el {
	&[readonly], &[disabled] {
		background-color: darken($formBackground, 4%);
	}
	&[disabled] {
		cursor: not-allowed;
	}
}

// Row wrap
.frm-el-wrap-row {
	.frm-label {
		padding-top: 12px;
	}
	&.frm-el-wrap-row-to-xlg .frm-label {
		@media (max-width: $screen-lg-max) {
			padding-top: 0;
		}
	}
	&.frm-el-wrap-row-to-lg .frm-label {
		@media (max-width: $screen-md-max) {
			padding-top: 0;
		}
	}
	&.frm-el-wrap-row-to-md .frm-label {
		@media (max-width: $screen-sm-max) {
			padding-top: 0;
		}
	}
	&.frm-el-wrap-row-to-sm .frm-label {
		@media (max-width: $screen-xs-max) {
			padding-top: 0;
		}
	}
	&.frm-el-wrap-row-to-xs .frm-label {
		@media (max-width: $screen-xxs-max) {
			padding-top: 0;
		}
	}
}