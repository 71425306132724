/**
 * Global mixins
 * @author Martin Adamec
 * @url http://adamecmartin.cz
 */



/**
 * Transform: 2D translate
 * @param {$x} X axis
 * @param {$y} Y axis
 */
@mixin translate2D($x, $y)
{
	transform: translate($x, $y);
}



/**
 * Clearfix
 * @param {$list} Array of class
 */
@mixin Clearfix($list)
{
	@each $item in $list {
		.#{$item} {
			&:before, &:after {
				content: " ";
				display: table;
			}
			&:after {
				clear: both;
			}
		}
	}
}



/**
 * Font properties
 * @param {$size}   Font-size
 * @param {$lh}     Line-height
 * @param {$weight} Font weight
 */
@mixin font($size, $lh: 1.3em, $weight: null)
{
	font-size: $size;
	// Line height
	@if $lh != null {
		line-height: $lh;
	}
	// Font weight
	@if $weight != null {
		font-weight: $weight;
	}
}



/**
 * Text decoration
 * @param {$normal} Text-decoration when non hover
 * @param {$hover} Text-decoration when hover, focus
 */
@mixin underline($normal: 0, $hover: 0)
{
	@if $normal == 1 {
		text-decoration: underline;
	}
	@else {
		text-decoration: none;
	}
	// Hover
	@if $hover == 1 {
		&:hover, &:focus {text-decoration: underline;}
	}
	@else {
		&:hover, &:focus {text-decoration: none;}
	}
}

/**
 * Gradient helper
 * @param {$x}    Top/left color
 * @param {$y}    Bottom/right color
 * @param {$bg}   Background color for old browser
 * @param {$type} Direction of gradient
 */
@mixin gradient($x, $y, $bg: null, $type: vertical)
{
	// Bg
	@if $bg == null {
		$bg: $x;
	}
	// Vertical
	@if $type == vertical
	{
		background: $x;
		background: linear-gradient(to bottom, $x 0%, $y 100%);
	}
	// Horizontal
	@else if $type == horizontal {
		background: $x;
		background: linear-gradient(to right, $x 0%, $y 100%);
	}
	// Horizontal
	@else {
		background: $x;
		background: linear-gradient($type, $x 0%, $y 100%);
	}
}


/**
 * Opacity
 */
@mixin opacity($val)
{
	filter: alpha (opacity=#{$val*100});
	opacity: $val;
}


/**
 * Flex grid
 * @param {$val} Property value
 */
@mixin flex($val: 1)
{
	box-flex: $val;
	// New version
	flex: $val;
}


/**
 * Headings
 */
@mixin headings {
	h1, h2, h3, h4, h5, h6 {
		@content;
	}
}


/**
 * Placeholder
 */
@mixin placeholderStyle {
	&::-webkit-input-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}


/**
 * Placeholder: color
 */
@mixin placeholderColor($color) {
	@include placeholderStyle {
		color: $color;
	}
}